import React, { useState, useEffect, useRef } from "react";
import { Column } from "@ant-design/charts";
import { getFincasVariablesAvgApi } from "../../../api/finca.api";
import { getAccessTokenApi } from "../../../api/auth";
import { Button } from "antd";

const Barras = (props) => {
  const { sostenibilidadId, fincaId, fechaInicial, fechaFinal } = props;
  const token = getAccessTokenApi();
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (sostenibilidadId > 0) {
      if (fincaId.length > 0) {
        if (fechaInicial && fechaFinal) {
          getFincasVariablesAvgApi(
            token,
            sostenibilidadId,
            fechaInicial,
            fechaFinal,
            fincaId
          ).then((response) => {
            setData(response.fincas);
          });
        } else {
          getFincasVariablesAvgApi(token, sostenibilidadId, 0, 0, fincaId).then(
            (response) => {
              setData(response.fincas);
            }
          );
        }
      } else {
        if (fechaInicial && fechaFinal) {
          getFincasVariablesAvgApi(
            token,
            sostenibilidadId,
            fechaInicial,
            fechaFinal,
            0
          ).then((response) => {
            setData(response.fincas);
          });
        } else {
          getFincasVariablesAvgApi(token, sostenibilidadId, 0, 0, 0).then(
            (response) => {
              setData(response.fincas);
            }
          );
        }
      }
    } else {
      setData([]);
    }
  }, [token, sostenibilidadId, fincaId, fechaInicial, fechaFinal]);

  //   var config = {
  //     data: data,
  //     xField: "name",
  //     yField: "avgNormalizado",
  //     label: {
  //       position: "middle",
  //       style: {
  //         fill: "#FFFFFF",
  //         opacity: 0.6,
  //       },
  //     },
  //     xAxis: {
  //       label: {
  //         autoHide: true,
  //         autoRotate: false,
  //       },
  //     },
  //     meta: {
  //       name: { alias: "Finca" },
  //       avgNormalizado: { alias: "Criticidad" },
  //     },
  //   };
  //   var config = {
  //     data: data,
  //     xField: "avgNormalizado",
  //     yField: "name",
  //     seriesField: "name",
  //     legend: { position: "top-left" },
  //     label: {
  //       position: "middle",
  //     },
  //     meta: {
  //       name: { alias: "Finca" },
  //       avgNormalizado: {
  //         alias: "Criticidad",
  //       },
  //     },
  //     annotations: [
  //       //   {
  //       //     type: "regionFilter",
  //       //     start: ["min", "median"],
  //       //     end: ["max", "0"],
  //       //     color: "#F4664A",
  //       //   },
  //       {
  //         type: "text",
  //         position: [5, 5],
  //         content: "Desempeño óptimo",
  //         offsetY: 0,
  //         style: { textBaseline: "bottom" },
  //       },
  //       {
  //         type: "line",
  //         start: [0, 5],
  //         end: [5, 5],
  //         style: {
  //           stroke: "#F4664A",
  //           lineDash: [2, 2],
  //         },
  //       },
  //     ],
  //   };
  var config = {
    data: data,
    xField: "name",
    yField: "avgNormalizado",
    seriesField: "name",
    label: {
      position: "bottom",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      name: { alias: "Finca" },
      avgNormalizado: { alias: "Criticidad", min: 0, max: 5 },
    },
    annotations: [
      //   {
      //     type: "regionFilter",
      //     start: ["min", "median"],
      //     end: ["max", "0"],
      //     color: "#F4664A",
      //   },
      {
        type: "text",
        position: ["min", 5],
        content: "Óptimo",
        offsetY: 15,
        offsetX: -145,
        style: { textBaseline: "bottom" },
      },
      {
        type: "line",
        start: ["min", 5],
        end: ["max", 5],
        style: {
          stroke: "#8eaadb",
          lineDash: [2, 2],
        },
      },
      {
        type: "text",
        position: ["min", 4],
        content: "Adecuado",
        offsetY: 15,
        offsetX: -145,
        style: { textBaseline: "bottom" },
      },
      {
        type: "line",
        start: ["min", 4],
        end: ["max", 4],
        style: {
          stroke: "#92d050",
          lineDash: [2, 2],
        },
      },
      {
        type: "text",
        position: ["min", 3],
        content: "Medio",
        offsetY: 15,
        offsetX: -145,
        style: { textBaseline: "bottom" },
      },
      {
        type: "line",
        start: ["min", 3],
        end: ["max", 3],
        style: {
          stroke: "#fdf734",
          lineDash: [2, 2],
        },
      },
      {
        type: "text",
        position: ["min", 2],
        content: "Bajo",
        offsetY: 15,
        offsetX: -145,
        style: { textBaseline: "bottom" },
      },
      {
        type: "line",
        start: ["min", 2],
        end: ["max", 2],
        style: {
          stroke: "#f8bf32",
          lineDash: [2, 2],
        },
      },
      {
        type: "text",
        position: ["min", 1],
        content: "Nulo",
        offsetY: 15,
        offsetX: -145,
        style: { textBaseline: "bottom" },
      },
      {
        type: "line",
        start: ["min", 1],
        end: ["max", 1],
        style: {
          stroke: "#ff0000",
          lineDash: [2, 2],
        },
      },
    ],
  };

  const ref = useRef();

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  return (
    <div>
      {/* <button type="button" onClick={downloadImage} style={{ marginRight: 24 }}>
        Guardar gráfico
      </button> */}
      <Button
        disabled={disabled}
        htmlType="button"
        className="btn-submit"
        onClick={downloadImage}
      >
        Guardar gráfico
      </Button>
      <br />
      <br />
      <Column
        {...config}
        onReady={(plot) => {
          ref.current = plot;
          setDisabled(false);
        }}
      />
    </div>
  );
  //return <Bar {...config} />;
};

export default Barras;
