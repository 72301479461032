import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import SelectSostenibilidad from "../../Sostenibilidad/SelectSostenibilidad";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateSostenibilidadApi,
  AddSostenibilidadApi,
  DeleteSostenibilidadApi,
} from "../../../../api/sostenibilidad.api";

import "./EditSostenibilidadForm.scss";

export default function EditSostenibilidadForm(props) {
  const { TextArea } = Input;
  const {
    sostenibilidad,
    setIsVisibleModal,
    setReloadSostenibilidades,
    buttonName,
    addSlag,
  } = props;
  const [sostenibilidadData, setSostenibilidadData] = useState({});
  const [ambitoId, setambitoId] = useState();
  const [atributoId, setAtributoId] = useState();
  const [criterioId, setCriterioId] = useState();

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setSostenibilidadData({
      codigo: "",
      name: "",
      criterioId: null,
      descripcion: "",
      objetivo: "",
      calculo: "",
      interpretacion: "",
      frecuencia: null,
      limitantes: "",
      requerimientos: "",
      base: "",
      responsable: "",
    });

    setambitoId(null);
    setAtributoId(null);
    setCriterioId(null);
  };

  useEffect(() => {
    if (sostenibilidad) {
      setSostenibilidadData({
        id: sostenibilidad.id,
        codigo: sostenibilidad.codigo,
        name: sostenibilidad.name,
        criterioId: sostenibilidad.criterioId,
        descripcion: sostenibilidad.descripcion,
        objetivo: sostenibilidad.objetivo,
        calculo: sostenibilidad.calculo,
        interpretacion: sostenibilidad.interpretacion,
        frecuencia: sostenibilidad.frecuencia,
        limitantes: sostenibilidad.limitantes,
        requerimientos: sostenibilidad.requerimientos,
        base: sostenibilidad.base,
        responsable: sostenibilidad.responsable,
      });
      setambitoId(sostenibilidad.criterio.atributo.ambitoId);
      setAtributoId(sostenibilidad.criterio.atributoId);
      setCriterioId(sostenibilidad.criterioId);
    } else {
      setSostenibilidadData({
        id: null,
        codigo: "",
        name: "",
        criterioId: null,
        descripcion: "",
        objetivo: "",
        calculo: "",
        interpretacion: "",
        frecuencia: "",
        limitantes: "",
        requerimientos: "",
        base: "",
        responsable: "",
      });
      setambitoId(null);
      setAtributoId(null);
      setCriterioId(null);
    }
  }, [sostenibilidad]);

  const addUpdateSostenibilidad = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();

    setSostenibilidadData({
      ...sostenibilidadData,
      criterioId: criterioId,
    });

    let sostenibilidadUpdate = sostenibilidadData;
    sostenibilidadData.criterioId = criterioId;

    if (!criterioId && addSlag !== "delete") {
      notification["error"]({
        message: "El criterio es obligatorio",
      });
      return;
    }

    if (!sostenibilidadUpdate.codigo || !sostenibilidadUpdate.name) {
      notification["error"]({
        message: "El código y nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddSostenibilidadApi(token, sostenibilidadUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadSostenibilidades(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateSostenibilidadApi(
        token,
        sostenibilidadUpdate,
        sostenibilidad.id
      ).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadSostenibilidades(true);
        setIsVisibleModal(false);
        // setSostenibilidadData({
        //   ...sostenibilidadData,
        // });
      });
    } else {
      DeleteSostenibilidadApi(token, sostenibilidad.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadSostenibilidades(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-sostenibilidad-form">
      <form className="form-edit" onSubmit={addUpdateSostenibilidad}>
        <Row gutter={24}>
          <SelectSostenibilidad
            addSlag={addSlag}
            ambitoId={ambitoId}
            setAmbitoId={setambitoId}
            atributoId={atributoId}
            setAtributoId={setAtributoId}
            criterioId={criterioId}
            setCriterioId={setCriterioId}
          ></SelectSostenibilidad>

          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Código"
                value={sostenibilidadData.codigo}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    codigo: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={sostenibilidadData.name}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    name: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Objetivo"
                value={sostenibilidadData.objetivo}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    objetivo: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={4}
                placeholder="Descripción"
                value={sostenibilidadData.descripcion}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    descripcion: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={4}
                placeholder="Método de cálculo"
                value={sostenibilidadData.calculo}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    calculo: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={4}
                placeholder="Interpretación del resultado"
                value={sostenibilidadData.interpretacion}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    interpretacion: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Frecuencia de cálculo"
                value={sostenibilidadData.frecuencia}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    frecuencia: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Limitantes"
                value={sostenibilidadData.limitantes}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    limitantes: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={4}
                placeholder="Requerimientos"
                value={sostenibilidadData.requerimientos}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    requerimientos: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Línea base"
                value={sostenibilidadData.base}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    base: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Responsable de generar los datos"
                value={sostenibilidadData.responsable}
                onChange={(e) =>
                  setSostenibilidadData({
                    ...sostenibilidadData,
                    responsable: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
