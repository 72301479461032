import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Empty, Breadcrumb } from "antd";
import { getAccessTokenApi } from "../../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./Atributo.scss";

import ModalUser from "../../../../components/Admin/ModalUser";
import EditAtributoForm from "../../../../components/Admin/Atributo/EditAtributoForm";
import { getAllAtributosApi } from "../../../../api/atributo.api";

export default function Atributo() {
  const [atributos, setAtributos] = useState([]);
  const token = getAccessTokenApi();
  const [reloadAtributos, setReloadAtributos] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getAllAtributosApi(token).then((response) => {
      setAtributos(response.atributos);
      setLoadingData(false);
    });

    setReloadAtributos(false);
  }, [token, reloadAtributos]);

  const columns = [
    {
      title: "Código",
      width: 40,
      dataIndex: "codigo",
      key: "codigo",
      fixed: "left",
    },
    {
      title: "Nombre",
      width: 80,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Ámbito",
      width: 100,
      dataIndex: ["ambito", "name"],
      key: "ambitoId",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editAtributo(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteAtributo(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const addAtributo = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo atributo");
    setDisabled(true);
    setmodalContent(
      <EditAtributoForm
        atributo={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadAtributos={setReloadAtributos}
        buttonName="Agregar atributo"
        addSlag={"add"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  const editAtributo = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar atributo");
    setDisabled(false);
    setmodalContent(
      <EditAtributoForm
        atributo={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadAtributos={setReloadAtributos}
        buttonName="Editar atributo"
        addSlag={"edit"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };

  const deleteAtributo = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar atributo");
    setDisabled(true);
    setmodalContent(
      <EditAtributoForm
        atributo={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadAtributos={setReloadAtributos}
        buttonName="Eliminar atributo"
        addSlag={"delete"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  return (
    <div className="atributos">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Inidicadores</Breadcrumb.Item>
        <Breadcrumb.Item>Atributo</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addAtributo}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={atributos}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen atributos"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
