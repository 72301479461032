import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification, Select } from "antd";
import SelectVariable from "../../Variable/SelectVariable";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateVariableApi,
  AddVariableApi,
  DeleteVariableApi,
} from "../../../../api/variable.api";

import "./EditVariableForm.scss";

export default function EditVariableForm(props) {
  const { Option } = Select;
  const { TextArea } = Input;
  const {
    variable,
    setIsVisibleModal,
    setReloadVariables,
    buttonName,
    addSlag,
  } = props;
  const [variableData, setVariableData] = useState({});
  const [ambitoId, setambitoId] = useState();
  const [atributoId, setAtributoId] = useState();
  const [criterioId, setCriterioId] = useState();
  const [sostenibilidadId, setSostenibilidadId] = useState();

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setVariableData({
      codigo: "",
      name: "",
      sostenibilidadId: null,
      ambitoId: null,
      descripcion: "",
      frecuencia: "",
      fuente: "",
      responsable: "",
      formula: "",
      var1: "",
      var2: "",
      var3: "",
      var4: "",
      var5: "",
      var6: "",
      var7: "",
      var8: "",
      tipo1: "",
      tipo2: "",
      tipo3: "",
      tipo4: "",
      tipo5: "",
      tipo6: "",
      tipo7: "",
      tipo8: "",
      unidad: "",
      normalizado: "",
      criticidad1: "",
      criticidad2: "",
      criticidad3: "",
      criticidad4: "",
      criticidad5: "",
      sindato: 0,
      resultadonormalizado: "",
      grafica1: "",
      comentariografica1: "",
      grafica2: "",
      comentariografica2: "",
    });

    setambitoId(null);
    setAtributoId(null);
    setCriterioId(null);
    setSostenibilidadId(null);
  };

  useEffect(() => {
    if (variable) {
      setVariableData({
        id: variable.id,
        codigo: variable.codigo,
        name: variable.name,
        sostenibilidadId: variable.criterioId,
        ambitoId: variable.ambitoId,
        descripcion: variable.descripcion,
        frecuencia: variable.frecuencia,
        fuente: variable.fuente,
        responsable: variable.responsable,
        formula: variable.formula,
        var1: variable.var1,
        var2: variable.var2,
        var3: variable.var3,
        var4: variable.var4,
        var5: variable.var5,
        var6: variable.var6,
        var7: variable.var7,
        var8: variable.var8,
        tipo1: variable.tipo1,
        tipo2: variable.tipo2,
        tipo3: variable.tipo3,
        tipo4: variable.tipo4,
        tipo5: variable.tipo5,
        tipo6: variable.tipo6,
        tipo7: variable.tipo7,
        tipo8: variable.tipo8,
        unidad: variable.unidad,
        normalizado: variable.normalizado,
        criticidad1: variable.criticidad1,
        criticidad2: variable.criticidad2,
        criticidad3: variable.criticidad3,
        criticidad4: variable.criticidad4,
        criticidad5: variable.criticidad5,
        sindato: variable.sindato,
        resultadonormalizado: variable.resultadonormalizado,
        grafica1: variable.grafica1,
        comentariografica1: variable.comentariografica1,
        grafica2: variable.grafica2,
        comentariografica2: variable.comentariografica2,
      });
      setambitoId(variable.sostenibilidad.criterio.atributo.ambitoId);
      setAtributoId(variable.sostenibilidad.criterio.atributoId);
      setCriterioId(variable.sostenibilidad.criterioId);
      setSostenibilidadId(variable.sostenibilidadId);
    } else {
      setVariableData({
        id: null,
        codigo: "",
        name: "",
        sostenibilidadId: null,
        ambitoId: null,
        descripcion: "",
        frecuencia: "",
        fuente: "",
        responsable: "",
        formula: "",
        var1: "",
        var2: "",
        var3: "",
        var4: "",
        var5: "",
        var6: "",
        var7: "",
        var8: "",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        unidad: "",
        normalizado: "",
        criticidad1: "",
        criticidad2: "",
        criticidad3: "",
        criticidad4: "",
        criticidad5: "",
        sindato: 0,
        resultadonormalizado: "",
        grafica1: "",
        comentariografica1: "",
        grafica2: "",
        comentariografica2: "",
      });
      setambitoId(null);
      setAtributoId(null);
      setCriterioId(null);
    }
  }, [variable]);

  const addUpdateVariable = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();

    setVariableData({
      ...variableData,
      sostenibilidadId: sostenibilidadId,
      ambitoId: ambitoId,
    });

    let variableUpdate = variableData;
    variableData.sostenibilidadId = sostenibilidadId;
    variableData.ambitoId = ambitoId;

    if (!sostenibilidadId && addSlag !== "delete") {
      notification["error"]({
        message: "El indicador de sostenibilidad es obligatorio",
      });
      return;
    }

    if (!variableUpdate.codigo || !variableUpdate.name) {
      notification["error"]({
        message: "El código y nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddVariableApi(token, variableUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadVariables(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateVariableApi(token, variableUpdate, variable.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadVariables(true);
        setIsVisibleModal(false);
        // setSostenibilidadData({
        //   ...sostenibilidadData,
        // });
      });
    } else {
      DeleteVariableApi(token, variable.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadVariables(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-sostenibilidad-form">
      <form className="form-edit" onSubmit={addUpdateVariable}>
        <Row gutter={24}>
          <SelectVariable
            addSlag={addSlag}
            ambitoId={ambitoId}
            setAmbitoId={setambitoId}
            atributoId={atributoId}
            setAtributoId={setAtributoId}
            criterioId={criterioId}
            setCriterioId={setCriterioId}
            sostenibilidadId={sostenibilidadId}
            setSostenibilidadId={setSostenibilidadId}
          ></SelectVariable>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Código"
                value={variableData.codigo}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    codigo: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={variableData.name}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    name: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={4}
                placeholder="Descripción"
                value={variableData.descripcion}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    descripcion: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Frecuencia de medición"
                value={variableData.frecuencia}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    frecuencia: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Fuente de la toma de datos"
                value={variableData.fuente}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    fuente: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Responsable"
                value={variableData.responsable}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    responsable: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={4}
                placeholder="Fórmula"
                value={variableData.formula}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    formula: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                placeholder="Variable 1"
                value={variableData.var1}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    var1: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                defaultValue="0"
                value={variableData.tipo1}
                placeholder="Tipo"
                onChange={(value) =>
                  setVariableData({
                    ...variableData,
                    tipo1: value,
                  })
                }
              >
                <Option value="0">Número</Option>
                {/* <Option value="1">Normalizado 1-5</Option> */}
              </Select>
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item>
              <Input
                placeholder="Variable 2"
                value={variableData.var2}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    var2: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                defaultValue="0"
                value={variableData.tipo2}
                placeholder="Tipo"
                onChange={(value) =>
                  setVariableData({
                    ...variableData,
                    tipo2: value,
                  })
                }
              >
                <Option value="0">Número</Option>
                {/* <Option value="1">Normalizado 1-5</Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                placeholder="Variable 3"
                value={variableData.var3}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    var3: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                defaultValue="0"
                value={variableData.tipo3}
                placeholder="Tipo"
                onChange={(value) =>
                  setVariableData({
                    ...variableData,
                    tipo3: value,
                  })
                }
              >
                <Option value="0">Número</Option>
                {/* <Option value="1">Normalizado 1-5</Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                placeholder="Variable 4"
                value={variableData.var4}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    var4: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                defaultValue="0"
                value={variableData.tipo4}
                placeholder="Tipo"
                onChange={(value) =>
                  setVariableData({
                    ...variableData,
                    tipo4: value,
                  })
                }
              >
                <Option value="0">Número</Option>
                {/* <Option value="1">Normalizado 1-5</Option> */}
              </Select>
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item>
              <Input
                placeholder="Variable 5"
                value={variableData.var5}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    var5: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                defaultValue="0"
                value={variableData.tipo5}
                placeholder="Tipo"
                onChange={(value) =>
                  setVariableData({
                    ...variableData,
                    tipo5: value,
                  })
                }
              >
                <Option value="0">Número</Option>
                {/* <Option value="1">Normalizado 1-5</Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                placeholder="Variable 6"
                value={variableData.var6}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    var6: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                defaultValue="0"
                value={variableData.tipo6}
                placeholder="Tipo"
                onChange={(value) =>
                  setVariableData({
                    ...variableData,
                    tipo6: value,
                  })
                }
              >
                <Option value="0">Número</Option>
                {/* <Option value="1">Normalizado 1-5</Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                placeholder="Variable 7"
                value={variableData.var7}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    var7: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                defaultValue="0"
                value={variableData.tipo7}
                placeholder="Tipo"
                onChange={(value) =>
                  setVariableData({
                    ...variableData,
                    tipo7: value,
                  })
                }
              >
                <Option value="0">Número</Option>
                {/* <Option value="1">Normalizado 1-5</Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                placeholder="Variable 8"
                value={variableData.var8}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    var8: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                defaultValue="0"
                value={variableData.tipo8}
                placeholder="Tipo"
                onChange={(value) =>
                  setVariableData({
                    ...variableData,
                    tipo8: value,
                  })
                }
              >
                <Option value="0">Número</Option>
                {/* <Option value="1">Normalizado 1-5</Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Unidades"
                value={variableData.unidad}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    unidad: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Resultado a ser normalizado"
                value={variableData.normalizado}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    normalizado: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Criticidad 1"
                value={variableData.criticidad1}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    criticidad1: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Criticidad 2"
                value={variableData.criticidad2}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    criticidad2: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Criticidad 3"
                value={variableData.criticidad3}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    criticidad3: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Criticidad 4"
                value={variableData.criticidad4}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    criticidad4: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Criticidad 5"
                value={variableData.criticidad5}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    criticidad5: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Sin dato"
                type="number"
                value={variableData.sindato}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    sindato: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Resultado normalizado"
                value={variableData.resultadonormalizado}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    resultadonormalizado: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Salida gráfica 1"
                value={variableData.grafica1}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    grafica1: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Comentario de análisis del evaluador salida gráfica 1"
                value={variableData.comentariografica1}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    comentariografica1: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Salida gráfica 2"
                value={variableData.grafica2}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    grafica2: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <TextArea
                rows={2}
                placeholder="Comentario de análisis del evaluador salida gráfica 2"
                value={variableData.comentariografica2}
                onChange={(e) =>
                  setVariableData({
                    ...variableData,
                    comentariografica2: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
