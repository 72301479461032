import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateAmbitoApi,
  AddAmbitoApi,
  DeleteAmbitoApi,
} from "../../../../api/ambito.api";

import "./EditAmbitoForm.scss";

export default function EditAmbitoForm(props) {
  const { ambito, setIsVisibleModal, setReloadAmbitos, buttonName, addSlag } =
    props;
  const [ambitoData, setAmbitoData] = useState({});

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setAmbitoData({
      codigo: "",
      name: "",
    });
  };

  useEffect(() => {
    if (ambito) {
      setAmbitoData({
        id: ambito.id,
        codigo: ambito.codigo,
        name: ambito.name,
      });
    } else {
      setAmbitoData({
        id: null,
        codigo: "",
        name: "",
      });
    }
  }, [ambito]);

  const addUpdateAmbito = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let ambitoUpdate = ambitoData;
    if (!ambitoUpdate.codigo || !ambitoUpdate.name) {
      notification["error"]({
        message: "El código y nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddAmbitoApi(token, ambitoUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadAmbitos(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateAmbitoApi(token, ambitoUpdate, ambito.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadAmbitos(true);
        setIsVisibleModal(false);
        setAmbitoData({
          ...ambitoData,
        });
      });
    } else {
      DeleteAmbitoApi(token, ambito.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadAmbitos(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-ambito-form">
      <form className="form-edit" onSubmit={addUpdateAmbito}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Código"
                value={ambitoData.codigo}
                onChange={(e) =>
                  setAmbitoData({ ...ambitoData, codigo: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={ambitoData.name}
                onChange={(e) =>
                  setAmbitoData({ ...ambitoData, name: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
