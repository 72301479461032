import React, { useState, useEffect } from "react";
import { Breadcrumb, Statistic, Card, Row, Col } from "antd";
import {
  BarsOutlined,
  BankOutlined,
  UserOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { Popup, MapContainer, TileLayer, Marker } from "react-leaflet";
import { getAllFincasIdApi } from "../../../api/finca.api";

import { getAccessTokenApi } from "../../../api/auth";
import fincaSvg from "../../../assets/img/svg/finca.svg";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import "./Geoposicion.scss";
import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

export default function Geoposicion() {
  const token = getAccessTokenApi();
  const [fincas, setFincas] = useState([]);
  const position = [-4.065372, -78.953444];
  const [fincaId, setFincaId] = useState([]);

  useEffect(() => {
    if (fincaId.length > 0) {
      getAllFincasIdApi(token, fincaId).then((response) => {
        setFincas(response.fincas);
      });
    } else {
      getAllFincasIdApi(token, 0).then((response) => {
        setFincas(response.fincas);
      });
    }
  }, [token, fincaId]);

  const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: "marker-cluster-custom",
      iconSize: L.point(60, 60, true),
    });
  };

  const iconFinca = new L.Icon({
    iconUrl: fincaSvg,
    iconRetinaUrl: fincaSvg,
    iconSize: [64, 64],
    iconAnchor: [32, 64],
    popupAnchor: [0, -46],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
  });

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Mapa</Breadcrumb.Item>
        <Breadcrumb.Item>Geoposición</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Row gutter={16}>
          <Col span={24}>
            <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <MapContainer
              style={{ width: "100%", height: "70vh" }}
              center={position}
              zoom={8}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
                {fincas && fincas.map
                  ? fincas.map(
                      (item) =>
                        item.center && (
                          <Marker
                            key={item.id}
                            position={item.center}
                            //icon={iconFinca}
                          >
                            <Popup>
                              <b>{item.name}</b> <br /> {item.productor}
                            </Popup>
                          </Marker>
                        )
                    )
                  : ""}
              </MarkerClusterGroup>
            </MapContainer>
          </Col>
        </Row>
      </div>
    </div>
  );
}
