import React, { useState, useEffect } from "react";
import { Breadcrumb, Row, Col, DatePicker } from "antd";

import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";
import SelectAllSostenibilidad from "../../../components/Admin/Sostenibilidad/SelectAllSostenibilidad";

import { getVariablesDataByIndicadorApi } from "../../../api/variable.api";

import Barras from "./Barras";
import Amoeba from "./Amoeba";
import Lineas from "./Lineas";

export default function Indicadores() {
  const token = getAccessTokenApi();
  const [variables, setVariables] = useState([]);
  const [sostenibilidadId, setSostenibilidadId] = useState([]);
  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [fincaId, setFincaId] = useState([]);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    if (sostenibilidadId > 0) {
      getVariablesDataByIndicadorApi(token, sostenibilidadId).then(
        (response) => {
          setVariables(response.variables);
        }
      );
    }
  }, [token, sostenibilidadId]);

  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   asyncFetch();
  // }, []);
  // const asyncFetch = () => {
  //   fetch(
  //     "https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json"
  //   )
  //     .then((response) => response.json())
  //     .then((json) => setData(json))
  //     .catch((error) => {
  //       console.log("fetch data failed", error);
  //     });
  // };

  function onChange(date, dateString) {
    if (date) {
      setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
      setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
    } else {
      setFechaInicial(null);
      setFechaFinal(null);
    }
  }

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Reportes</Breadcrumb.Item>
        <Breadcrumb.Item>Indicadores</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Row gutter={16}>
          <Col span={8}>
            <SelectAllSostenibilidad
              setSostenibilidadId={setSostenibilidadId}
            ></SelectAllSostenibilidad>
          </Col>
          <Col span={8}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={8}>
            <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca>
          </Col>
        </Row>

        <br />
        <h2>Criticidad global</h2>
        <br />
        <Barras
          sostenibilidadId={sostenibilidadId}
          fincaId={fincaId}
          fechaInicial={fechaInicial}
          fechaFinal={fechaFinal}
        />
        <br />
        <h2>Criticidad por indicador secundario</h2>
        <br />
        <Amoeba
          sostenibilidadId={sostenibilidadId}
          fincaId={fincaId}
          fechaInicial={fechaInicial}
          fechaFinal={fechaFinal}
        />
        <br />
        <h2>Indicadores secundarios</h2>
        <br />
        {variables && variables.map
          ? variables.map((item) => (
              <div key={item.id}>
                <h3>
                  {item.codigo} - {item.name}
                </h3>
                <p>{item.descripcion}</p>
                <Lineas
                  variableId={item.id}
                  fincaId={fincaId}
                  fechaInicial={fechaInicial}
                  fechaFinal={fechaFinal}
                ></Lineas>
                <br />
                <br />
              </div>
            ))
          : ""}
      </div>
    </div>
  );
}
