import React, { useState, useEffect } from "react";
import { Modal, Button, notification } from "antd";
import {
  //Map,
  MapContainer,
  TileLayer,
  //Polygon,
  FeatureGroup,
  //Circle,
  useMapEvent,
  //useMap,
} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";

import { getAccessTokenApi } from "../../../../api/auth";

import { UpdateFincaApi } from "../../../../api/finca.api";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

L.drawLocal = {
  draw: {
    toolbar: {
      // #TODO: this should be reorganized where actions are nested in actions
      // ex: actions.undo  or actions.cancel
      actions: {
        title: "Cancelar la edición de la zona",
        text: "Cancelar",
      },
      finish: {
        title: "Finalizar edición de la zona",
        text: "Finalizar",
      },
      undo: {
        title: "Eliminar último punto",
        text: "Deshacer",
      },
      buttons: {
        polyline: "- your text-",
        polygon: "Dibujar la zona de la finca",
        rectangle: "- your text-",
        circle: "- your text-",
        marker: "- your text-",
        circlemarker: "- your text-",
      },
    },
    handlers: {
      circle: {
        tooltip: {
          start: "- your text-",
        },
        radius: "- your text-",
      },
      circlemarker: {
        tooltip: {
          start: "- your text-.",
        },
      },
      marker: {
        tooltip: {
          start: "- your text-.",
        },
      },
      polygon: {
        tooltip: {
          start: "Clic para iniciar a dibujar la zona",
          cont: "Clic para el siguiente punto de la zona",
          end: "Clic en el primer punto para cerrar la zona",
        },
      },
      polyline: {
        error: "<strong>Error:</strong> shape edges cannot cross!",
        tooltip: {
          start: "Click to start drawing line.",
          cont: "Click to continue drawing line.",
          end: "Click last point to finish line.",
        },
      },
      rectangle: {
        tooltip: {
          start: "- your text-.",
        },
      },
      simpleshape: {
        tooltip: {
          end: "Release mouse to finish drawing.",
        },
      },
    },
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: "Guardar cambios",
          text: "Finalizar",
        },
        cancel: {
          title: "Cancelar edición, descartar todos los cambios",
          text: "Cancelar",
        },
        clearAll: {
          title: "Eliminar todas las zonas",
          text: "Eliinar todo",
        },
      },
      buttons: {
        edit: "Editar zona",
        editDisabled: "No existe una zona para editar",
        remove: "Eliminar zona",
        removeDisabled: "No existe una zona para eliminar",
      },
    },
    handlers: {
      edit: {
        tooltip: {
          text: "Mueva los puntos para editar la zona.",
          subtext: "Clic en cancelar para deshacer los cambios.",
        },
      },
      remove: {
        tooltip: {
          text: "Clic en la zona para eliminarla.",
        },
      },
    },
  },
};

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png",
});

// let polyline;
let _editableFG;

function MyComponent(props) {
  const { center } = props;
  // const map = useMapEvent("click", () => {
  //   map.setView([50.5, 30.5], 15);
  // });
  const map = useMapEvent({
    // TODO resolve onload
    // layeradd() {
    //   console.log(center);
    //   //map.flyTo(centerLayer, map.getZoom());
    // },
    // locationfound(e) {
    //   //map.flyTo(e.latlng, map.getZoom());
    //   console.log(e);
    // },
  });
  useEffect(() => {
    //console.log(center);
    map.flyTo(center, map.getZoom());
  }, [center]);
  return null;
}

export default function MapFinca(props) {
  const {
    title,
    isVisibleModal,
    setIsVisibleModal,
    fincaData,
    setFincaData,
    setReloadFincas,
    center,
  } = props;
  // const purpleOptions = { color: "purple" };
  const [disabled, setDisabled] = useState(true);
  const [newZona, setNewZona] = useState({});
  const [newCenter, setNewcenter] = useState([]);
  //const [zona, setZona] = useState();

  // restore
  //L.geoJSON(JSON.parse(shape_for_db)).addTo(mymap);

  // const polygon = [
  //   [51.515, -0.09],
  //   [51.52, -0.1],
  //   [51.52, -0.12],
  // ];

  //const map = useMap();

  useEffect(() => {
    //console.log(fincaData.id);
    var shape_for_db = JSON.stringify(newZona);
    setFincaData({
      ...fincaData,
      zona: shape_for_db,
    });
  }, [newZona]);

  useEffect(() => {
    console.log(fincaData.id);
    setFincaData({
      ...fincaData,
      center: [newCenter.lat, newCenter.lng],
    });
  }, [newCenter]);

  const handleCancel = () => {
    setIsVisibleModal(false);
  };

  const _onEdited = (e) => {
    e.layers.eachLayer((layer) => {
      setDisabled(false);
      var shape = layer.toGeoJSON();
      let centerLayer = layer.getBounds().getCenter();
      setNewcenter(centerLayer);
      setNewZona(shape);

      return;
    });

    _onChange();
  };

  const _onCreated = (e) => {
    // let type = e.layerType;
    let layer = e.layer;
    setDisabled(false);
    //setZona(layer.getLatLngs());

    var shape = layer.toGeoJSON();
    //setZona(shape);
    let centerLayer = layer.getBounds().getCenter();

    setNewcenter(centerLayer);
    setNewZona(shape);

    _onChange();
  };

  const _onDeleted = (e) => {
    setDisabled(false);
    //setFincaData({ ...fincaData, zona: null, center: null });
    setNewcenter(null);
    setNewZona(null);
    _onChange();
  };

  // const _onMounted = (drawControl) => {
  //   console.log("_onMounted", drawControl);
  // };

  // const _onEditStart = (e) => {
  //   console.log("_onEditStart", e);
  // };

  // const _onEditStop = (e) => {
  //   console.log("_onEditStop", e);
  // };

  // const _onDeleteStart = (e) => {
  //   console.log("_onDeleteStart", e);
  // };

  // const _onDeleteStop = (e) => {
  //   console.log("_onDeleteStop", e);
  // };

  const _onFeatureGroupReady = (reactFGref) => {
    // populate the leaflet FeatureGroup with the geoJson layers
    //const map = useMap();
    if (_editableFG) {
      _editableFG.clearLayers();
    } else {
      let leafletFG = reactFGref;
      if (leafletFG) {
        leafletFG.clearLayers();
      }
    }
    if (fincaData.zona) {
      let geojsonFeature = JSON.parse(fincaData.zona);
      var myLayer = L.geoJSON();
      myLayer.addData(geojsonFeature);

      myLayer.eachLayer(function (l) {
        if (l instanceof L.Polygon) {
          let leafletFG = reactFGref;

          if (_editableFG) {
            l.addTo(_editableFG);
          } else {
            if (leafletFG) {
              l.addTo(leafletFG);
            }
          }
          //map.setView(centerLayer, 15);

          // store the ref for future access to content
          _editableFG = reactFGref;
        }
      });
    }
    if (fincaData.center) {
      //map.setView(fincaData.center, 15);
    }
  };

  const _onChange = () => {
    // this._editableFG contains the edited geometry, which can be manipulated through the leaflet API

    const { onChange } = props;

    if (!_editableFG || !onChange) {
      return;
    }

    const geojsonData = _editableFG.toGeoJSON();
    onChange(geojsonData);
  };

  const saveZona = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    // let zonaUpdate = zona;
    // if (!zonaUpdate) {
    //   notification["error"]({
    //     message: "No existe una zona para guardar",
    //   });
    //   return;
    // }
    UpdateFincaApi(token, fincaData, fincaData.id).then((result) => {
      notification["success"]({
        message: result.message,
      });
      setReloadFincas(true);
      setDisabled(true);
      //setIsVisibleModal(false);
      // setFincaData({
      //   ...fincaData,
      // });
    });
  };

  return (
    <>
      <Modal
        title={title}
        centered={true}
        visible={isVisibleModal}
        onCancel={handleCancel}
        footer={false}
        animation={false}
      >
        <MapContainer
          style={{ width: "100%", height: "70vh" }}
          center={center}
          zoom={14}
          zoomControl={true}
        >
          <MyComponent center={center} />
          <TileLayer
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          <FeatureGroup
            ref={(reactFGref) => {
              _onFeatureGroupReady(reactFGref);
            }}
          >
            <EditControl
              position="topright"
              onEdited={(e) => {
                _onEdited(e);
              }}
              onCreated={(e) => {
                _onCreated(e);
              }}
              onDeleted={(e) => {
                _onDeleted(e);
              }}
              // onMounted={(e) => {_onMounted(e);
              // }}
              // onEditStart={(e) => {_onEditStart(e);
              // }}
              // onEditStop={(e) => {_onEditStop(e);
              // }}
              // onDeleteStart={(e) => {_onDeleteStart(e);
              // }}
              // onDeleteStop={(e) => {_onDeleteStop(e);
              // }}
              draw={{
                rectangle: false,
                circle: false,
                polyline: false,
                marker: false,
                circlemarker: false,
              }}
            />
          </FeatureGroup>
        </MapContainer>
        <br />
        <Button
          type="primary"
          disabled={disabled}
          htmlType="button"
          className="btn-submit"
          onClick={saveZona}
        >
          Guardar zona
        </Button>
        {/* <MapContainer
          style={{ width: "100%", height: "80vh" }}
          center={center}
          zoom={13}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Polygon pathOptions={purpleOptions} positions={polygon} />
        </MapContainer> */}
      </Modal>
    </>
  );
}

// function getGeoJson() {
//   return {
//     type: "FeatureCollection",
//     features: [
//       {
//         type: "Feature",
//         properties: {},
//         geometry: {
//           type: "LineString",
//           coordinates: [
//             [-122.47979164123535, 37.830124319877235],
//             [-122.47721672058105, 37.809377088502615],
//           ],
//         },
//       },
//       {
//         type: "Feature",
//         properties: {},
//         geometry: {
//           type: "Point",
//           coordinates: [-122.46923446655273, 37.80293476836673],
//         },
//       },
//       {
//         type: "Feature",
//         properties: {},
//         geometry: {
//           type: "Point",
//           coordinates: [-122.48399734497069, 37.83466623607849],
//         },
//       },
//       {
//         type: "Feature",
//         properties: {},
//         geometry: {
//           type: "Point",
//           coordinates: [-122.47867584228514, 37.81893781173967],
//         },
//       },
//       {
//         type: "Feature",
//         properties: {},
//         geometry: {
//           type: "Polygon",
//           coordinates: [
//             [
//               [-122.48069286346434, 37.800637436707525],
//               [-122.48069286346434, 37.803104310307276],
//               [-122.47950196266174, 37.803104310307276],
//               [-122.47950196266174, 37.800637436707525],
//               [-122.48069286346434, 37.800637436707525],
//             ],
//           ],
//         },
//       },
//       {
//         type: "Feature",
//         properties: {},
//         geometry: {
//           type: "Polygon",
//           coordinates: [
//             [
//               [-122.48103886842728, 37.833075326166274],
//               [-122.48065531253813, 37.832558431940114],
//               [-122.4799284338951, 37.8322660885204],
//               [-122.47963070869446, 37.83231693093747],
//               [-122.47948586940764, 37.832467339549524],
//               [-122.47945636510849, 37.83273426112019],
//               [-122.47959315776825, 37.83289737938241],
//               [-122.48004108667372, 37.833109220743104],
//               [-122.48058557510376, 37.83328293020496],
//               [-122.48080283403395, 37.83332529830436],
//               [-122.48091548681259, 37.83322785163939],
//               [-122.48103886842728, 37.833075326166274],
//             ],
//           ],
//         },
//       },
//       {
//         type: "Feature",
//         properties: {},
//         geometry: {
//           type: "Polygon",
//           coordinates: [
//             [
//               [-122.48043537139893, 37.82564992009924],
//               [-122.48129367828368, 37.82629397920697],
//               [-122.48240947723389, 37.82544653184479],
//               [-122.48373985290527, 37.82632787689904],
//               [-122.48425483703613, 37.82680244295304],
//               [-122.48605728149415, 37.82639567223645],
//               [-122.4898338317871, 37.82663295542695],
//               [-122.4930953979492, 37.82415839321614],
//               [-122.49700069427489, 37.821887146654376],
//               [-122.4991464614868, 37.82171764783966],
//               [-122.49850273132326, 37.81798857543524],
//               [-122.50923156738281, 37.82090404811055],
//               [-122.51232147216798, 37.823344820392535],
//               [-122.50150680541992, 37.8271414168374],
//               [-122.48743057250977, 37.83093781796035],
//               [-122.48313903808594, 37.82822612280363],
//               [-122.48043537139893, 37.82564992009924],
//             ],
//           ],
//         },
//       },
//     ],
//   };
// }
