import { List, Empty, Card, Spin } from "antd";
import { countVariableEncuestasVariableApi } from "../../../../api/variableEncuesta.api";
import { getAccessTokenApi } from "../../../../api/auth";
import { useState, useEffect } from "react";

import BorderOutlined from "@ant-design/icons/BorderOutlined";
import CheckSquareOutlined from "@ant-design/icons/CheckSquareOutlined";

export default function Secundarios(props) {
  const {
    secundarioData,
    setSecundarioId,
    setSecundarioSelectData,
    current,
    setCurrent,
    encuesta,
  } = props;
  const [loadigData, setloadigData] = useState(true);
  const token = getAccessTokenApi();

  useEffect(() => {
    if (secundarioData) setloadigData(false);
  }, []);

  const handleSecundarioClick = (id, object) => {
    setSecundarioId(id);
    setSecundarioSelectData(object);
    setCurrent(current + 1);
  };

  function CountVars({ variableId, encuestaId }) {
    const [contadorEncuesta, setContadorEncuesta] = useState(null);

    useEffect(() => {
      let isMounted = true;
      countVariableEncuestasVariableApi(token, encuestaId, variableId).then(
        (response) => {
          if (isMounted) setContadorEncuesta(response.count);
        }
      );
      return () => {
        isMounted = false;
      };
    }, [variableId, encuestaId]);

    if (contadorEncuesta === null) {
      return <Spin size="small" />;
    }
    if (contadorEncuesta > 0) {
      return (
        <>
          <CheckSquareOutlined style={{ color: "#52c41a" }} />
        </>
      );
    }
    return (
      <>
        <BorderOutlined style={{ color: "#ff4d4f" }} />
      </>
    );
  }

  return (
    <List
      dataSource={secundarioData}
      loading={loadigData}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No existen indicadores secundarios"
          />
        ),
      }}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 6,
        xxl: 3,
      }}
      renderItem={(variable) => (
        <List.Item>
          <Card
            hoverable
            title={variable.codigo}
            extra={
              <CountVars variableId={variable.id} encuestaId={encuesta.id} />
            }
            onClick={() => {
              handleSecundarioClick(variable.id, variable);
            }}
          >
            {variable.name}
          </Card>
        </List.Item>
      )}
    />
  );
}
