import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Breadcrumb, Empty, Progress, Spin } from "antd";
import { getAccessTokenApi } from "../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import FileDoneOutlined from "@ant-design/icons/FileDoneOutlined";

import { getAllEncuestasApi } from "../../../api/encuesta.api";
import { getAmbitosApi } from "../../../api/ambito.api";

import { countVariablesApi } from "../../../api/variable.api";
import { countVariableEncuestasApi } from "../../../api/variableEncuesta.api";

import ModalUser from "../../../components/Admin/ModalUser";
import VariableEncuesta from "../../../components/Admin/Encuesta/VariableEncuesta/VariableEncuesta";
import EditEncuestaForm from "../../../components/Admin/Encuesta/EditEncuestaForm";

import { formatDate, formatMonth, formatYear } from "../../../utils/functions";

import "./Encuestas.scss";

export default function Encuestas() {
  const [encuestas, setEncuestas] = useState([]);
  const token = getAccessTokenApi();
  const [reloadEncuestas, setReloadEncuestas] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [current, setCurrent] = useState(0);
  const [isVisibleModalVariable, setIsVisibleModalVariable] = useState(false);
  const [modalTitleVariable, setModalTitleVariable] = useState();

  const [ambitoData, setAmbitoData] = useState([]);

  const [encuesta, setEncuesta] = useState({});

  useEffect(() => {
    getAllEncuestasApi(token).then((response) => {
      setEncuestas(response.encuestas);
      setLoadingData(false);
    });
    return () => {
      setReloadEncuestas(false);
    };
  }, [token, reloadEncuestas]);

  useEffect(() => {
    getAmbitosApi(token).then((response) => {
      setAmbitoData(response.ambitos);
    });
  }, []);

  const addEncuesta = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nueva encuesta");
    setmodalContent(
      <EditEncuestaForm
        encuesta={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEncuestas={setReloadEncuestas}
        buttonName="Agregar encuesta"
        addSlag={"add"}
      />
    );
  };

  const editEncuesta = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar encuesta");
    setmodalContent(
      <EditEncuestaForm
        encuesta={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEncuestas={setReloadEncuestas}
        buttonName="Editar encuesta"
        addSlag={"edit"}
      />
    );
  };

  const deleteEncuesta = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar encuesta");
    setmodalContent(
      <EditEncuestaForm
        encuesta={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEncuestas={setReloadEncuestas}
        buttonName="Eliminar encuesta"
        addSlag={"delete"}
      />
    );
  };

  const insertVariable = (record) => {
    setEncuesta(record);
    setCurrent(0);
    setIsVisibleModalVariable(true);
    setModalTitleVariable(
      `Registrar variables encuesta (código: ${record.id}, fecha: ${formatDate(
        record.fecha
      )})`
    );
  };

  function CountVars({ encuestaId }) {
    const [contador, setContador] = useState(null);
    const [contadorEncuesta, setContadorEncuesta] = useState(null);
    useEffect(() => {
      let isMounted = true;
      countVariablesApi(token).then((response) => {
        if (isMounted) setContador(response.count);
      });
      return () => {
        isMounted = false;
      };
    }, []);

    useEffect(() => {
      let isMounted = true;
      countVariableEncuestasApi(token, encuestaId).then((response) => {
        if (isMounted) setContadorEncuesta(response.count);
      });
      return () => {
        isMounted = false;
      };
    }, [encuestaId]);

    if (contador === null || contadorEncuesta === null) {
      return <Spin size="small" />;
    }
    if (contadorEncuesta < contador) {
      const percentVal = (contadorEncuesta / contador) * 100;
      return (
        <>
          <Progress percent={percentVal.toFixed(0)} steps={4} />
          <br />
          {contadorEncuesta} de {contador}
        </>
      );
    }
    return (
      <>
        <Progress percent={100} steps={4} />
        <br />
        {contadorEncuesta} de {contador}
      </>
    );
  }

  const uniqueValues = (data) => (formatter) => {
    const uniqueTags = [];
    const uniqueTagsReturn = [];

    data.map((item) => {
      if (uniqueTags.indexOf(item.sistema) === -1) {
        uniqueTags.push(item.sistema);
      }
    });

    uniqueTags.map((item) => {
      uniqueTagsReturn.push({
        text: formatter(item),
        value: formatter(item),
      });
    });

    return uniqueTagsReturn;
  };

  const uniqueValuesFinca = (data) => (formatter) => {
    const uniqueTags = [];
    const uniqueTagsReturn = [];

    data.map((item) => {
      if (uniqueTags.indexOf(item.finca.name) === -1) {
        uniqueTags.push(item.finca.name);
      }
    });

    uniqueTags.map((item) => {
      uniqueTagsReturn.push({
        text: formatter(item),
        value: formatter(item),
      });
    });

    return uniqueTagsReturn;
  };

  const columns = [
    {
      title: "Código",
      width: 100,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Año",
      dataIndex: "fecha",
      key: "fecha",
      fixed: "left",
      render: (fecha) => {
        return formatYear(fecha);
      },
    },
    {
      title: "Mes",
      dataIndex: "fecha",
      key: "fecha",
      fixed: "left",
      render: (fecha) => {
        return formatMonth(fecha);
      },
    },
    {
      title: "Finca",
      width: 150,
      dataIndex: ["finca", "name"],
      key: "fincaId",
      fixed: "left",
      filters: uniqueValuesFinca(encuestas)((i) => i),
      onFilter: (value, record) => record.finca.name.indexOf(value) === 0,
      sorter: (a, b) => a.finca.name.localeCompare(b.finca.name),
      sortDirections: ["descend"],
    },
    {
      title: "Usuario",
      width: 150,
      dataIndex: ["user", "name"],
      key: "userId",
      render: (value, row, index) => {
        if (row.user && row.user.lastname) {
          return value + " " + row.user.lastname;
        } else {
          return "NA";
        }
      },
    },
    {
      title: "Fecha encuesta",
      dataIndex: "fecha",
      key: "fecha",
      render: (fecha) => {
        return formatDate(fecha);
      },
    },
    {
      title: "Variables ingresadas",
      width: 100,
      dataIndex: "id",
      key: "id",
      render: (id) => {
        return <CountVars encuestaId={id} />;
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 130,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="default"
            title="Cargar variables"
            className="margin-right-minimun"
            onClick={() => insertVariable(record)}
          >
            <FileDoneOutlined />
          </Button>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editEncuesta(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteEncuesta(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="encuestas">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Registro de datos</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addEncuesta}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={encuestas}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen encuestas"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
      >
        {modalContent}
      </ModalUser>

      <VariableEncuesta
        title={modalTitleVariable}
        isVisibleModal={isVisibleModalVariable}
        setIsVisibleModal={setIsVisibleModalVariable}
        ambitoData={ambitoData}
        encuesta={encuesta}
        current={current}
        setCurrent={setCurrent}
      />
    </div>
  );
}
