import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Space,
  DatePicker,
} from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateFincaApi,
  AddFincaApi,
  DeleteFincaApi,
} from "../../../../api/finca.api";

import moment from "moment";

import "./EditFincaForm.scss";

export default function EditFincaForm(props) {
  const { finca, setIsVisibleModal, setReloadFincas, buttonName, addSlag } =
    props;
  const [fincaData, setFincaData] = useState({});
  const dateFormat = "YYYY/MM/DD";
  const [lat, setLat] = useState();
  const [lon, setLon] = useState();

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setFincaData({
      codigo: "",
      name: "",
      sistema: "",
      sector: "",
      parroquia: "",
      secundario: "",
      productor: "",
      celular: "",
      fecha: null,
      canton: "",
      cultivos: null,
      pastizal: null,
      area: null,
      bosque: null,
      luzaras: null,
      hectareas: null,
      center: null,
    });
  };

  useEffect(() => {
    if (finca) {
      setFincaData({
        id: finca.id,
        codigo: finca.codigo,
        name: finca.name,
        sistema: finca.sistema,
        sector: finca.sector,
        parroquia: finca.parroquia,
        secundario: finca.secundario,
        productor: finca.productor,
        celular: finca.celular,
        fecha: finca.fecha ? finca.fecha : moment.now(),
        canton: finca.canton,
        cultivos: finca.cultivos,
        pastizal: finca.pastizal,
        area: finca.area,
        bosque: finca.bosque,
        luzaras: finca.luzaras,
        hectareas: finca.hectareas,
        center: finca.center,
      });
    } else {
      setFincaData({
        id: null,
        name: "",
        sistema: "",
        sector: "",
        parroquia: "",
        secundario: "",
        productor: "",
        celular: "",
        fecha: moment.now(),
        canton: "",
        cultivos: null,
        pastizal: null,
        area: null,
        bosque: null,
        luzaras: null,
        hectareas: null,
        center: null,
      });
    }
  }, [finca]);

  const addUpdateFinca = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let fincaUpdate = fincaData;
    if (!fincaUpdate.productor || !fincaUpdate.name) {
      notification["error"]({
        message: "El nombre de la finca y del productor son obligatorios",
      });
      return;
    }

    if (lat && lon) {
      setFincaData({ ...fincaData, center: [lat, lon] });
      fincaUpdate.center = [lat, lon];
    }

    if (addSlag === "add") {
      AddFincaApi(token, fincaUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadFincas(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateFincaApi(token, fincaUpdate, finca.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadFincas(true);
        setIsVisibleModal(false);
        setFincaData({
          ...fincaData,
        });
      });
    } else {
      DeleteFincaApi(token, finca.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadFincas(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-finca-form">
      <form className="form-edit" onSubmit={addUpdateFinca}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Nombre de la finca">
              <Input
                //placeholder="Nombre de la finca"
                value={fincaData.name}
                onChange={(e) =>
                  setFincaData({ ...fincaData, name: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Principal actividad económica">
              <Input
                //placeholder="Principal actividad económica"
                value={fincaData.secundario}
                onChange={(e) =>
                  setFincaData({ ...fincaData, secundario: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tipo de sistemas">
              <Input
                //placeholder="Tipo de sistemas"
                value={fincaData.sistema}
                onChange={(e) =>
                  setFincaData({ ...fincaData, sistema: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Cantón">
              <Input
                //placeholder="Cantón"
                value={fincaData.canton}
                onChange={(e) =>
                  setFincaData({ ...fincaData, canton: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Parroquia">
              <Input
                //placeholder="Parroquia"
                value={fincaData.parroquia}
                onChange={(e) =>
                  setFincaData({ ...fincaData, parroquia: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Sector-Comunidad">
              <Input
                //placeholder="Sector-Comunidad"
                value={fincaData.sector}
                onChange={(e) =>
                  setFincaData({ ...fincaData, sector: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item label="Nombre del productor">
              <Input
                placeholder="Nombre del productor"
                value={fincaData.productor}
                onChange={(e) =>
                  setFincaData({ ...fincaData, productor: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Celular">
              <Input
                //placeholder="Celular"
                value={fincaData.celular}
                onChange={(e) =>
                  setFincaData({ ...fincaData, celular: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Total cultivos">
              <Input
                //placeholder="Total cultivos"
                type="number"
                value={fincaData.cultivos}
                onChange={(e) =>
                  setFincaData({ ...fincaData, cultivos: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Total pastizal">
              <Input
                //placeholder="Total pastizal"
                type="number"
                value={fincaData.pastizal}
                onChange={(e) =>
                  setFincaData({ ...fincaData, pastizal: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Área de reforestación">
              <Input
                //placeholder="Área de reforestación"
                type="number"
                value={fincaData.area}
                onChange={(e) =>
                  setFincaData({ ...fincaData, area: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Bosque nativo">
              <Input
                //placeholder="Bosque nativo"
                type="number"
                value={fincaData.bosque}
                onChange={(e) =>
                  setFincaData({ ...fincaData, bosque: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Luzaras">
              <Input
                //placeholder="Luzaras"
                type="number"
                value={fincaData.luzaras}
                onChange={(e) =>
                  setFincaData({ ...fincaData, luzaras: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Nro total de hectáreas">
              <Input
                //placeholder="Nro total de hectáreas"
                type="number"
                value={fincaData.hectareas}
                onChange={(e) =>
                  setFincaData({ ...fincaData, hectareas: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Latitud">
              <Input
                //placeholder="Nro total de hectáreas"
                type="number"
                value={fincaData.center ? fincaData.center[0] : null}
                onChange={(e) => {
                  setFincaData({ ...fincaData, center: [e.target.value, lon] });
                  setLat(e.target.value);
                }}
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Longitud">
              <Input
                //placeholder="Nro total de hectáreas"
                type="number"
                value={fincaData.center ? fincaData.center[1] : null}
                onChange={(e) => {
                  setFincaData({ ...fincaData, center: [lat, e.target.value] });
                  setLon(e.target.value);
                }}
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Fecha inicio evaluación">
              <Space direction="vertical">
                <DatePicker
                  //placeholder="Fecha inicio evaluación"
                  defaultValue={moment(fincaData.fecha)}
                  format={dateFormat}
                  disabled={addSlag === "delete" ? true : false}
                  onChange={(date, dateString) =>
                    setFincaData({ ...fincaData, fecha: date })
                  }
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
