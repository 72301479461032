import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Breadcrumb, Empty } from "antd";
import { getAccessTokenApi } from "../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import AimOutlined from "@ant-design/icons/AimOutlined";

import { getAllFincasApi } from "../../../api/finca.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditFincaForm from "../../../components/Admin/Finca/EditFincaForm";

import { formatDate } from "../../../utils/functions";
import MapFinca from "../../../components/Admin/Finca/MapFinca";

import "./Fincas.scss";

export default function Fincas() {
  const [fincas, setFincas] = useState([]);
  const token = getAccessTokenApi();
  const [reloadFincas, setReloadFincas] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [isVisibleModalFinca, setIsVisibleModalFinca] = useState(false);
  const [modalTitleFinca, setModalTitleFinca] = useState();
  const [center, setCenter] = useState([-4.065372, -78.953444]);
  const [fincaData, setFincaData] = useState({});

  useEffect(() => {
    getAllFincasApi(token).then((response) => {
      setFincas(response.fincas);
      setLoadingData(false);
    });

    setReloadFincas(false);
  }, [token, reloadFincas]);

  const addFinca = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nueva finca");
    setmodalContent(
      <EditFincaForm
        finca={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadFincas={setReloadFincas}
        buttonName="Agregar finca"
        addSlag={"add"}
      />
    );
  };

  const editFinca = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar finca");
    setmodalContent(
      <EditFincaForm
        finca={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadFincas={setReloadFincas}
        buttonName="Editar finca"
        addSlag={"edit"}
      />
    );
  };

  const deleteFinca = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar finca");
    setmodalContent(
      <EditFincaForm
        finca={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadFincas={setReloadFincas}
        buttonName="Eliminar finca"
        addSlag={"delete"}
      />
    );
  };

  const mapFincaShow = (record) => {
    setFincaData(record);
    //console.log(fincaData);
    setModalTitleFinca(`Zona geográfica de la finca ${record.name}`);
    if (record.center) {
      setCenter(record.center);
    } else {
      setCenter([-4.065372, -78.953444]);
    }
    setIsVisibleModalFinca(true);
  };

  const uniqueValues = (data) => (formatter) => {
    const uniqueTags = [];
    const uniqueTagsReturn = [];

    data.map((item) => {
      if (uniqueTags.indexOf(item.sistema) === -1) {
        uniqueTags.push(item.sistema);
      }
    });

    uniqueTags.map((item) => {
      uniqueTagsReturn.push({
        text: formatter(item),
        value: formatter(item),
      });
    });

    return uniqueTagsReturn;
  };

  const uniqueValuesFinca = (data) => (formatter) => {
    const uniqueTags = [];
    const uniqueTagsReturn = [];

    data.map((item) => {
      if (uniqueTags.indexOf(item.name) === -1) {
        uniqueTags.push(item.name);
      }
    });

    uniqueTags.map((item) => {
      uniqueTagsReturn.push({
        text: formatter(item),
        value: formatter(item),
      });
    });

    return uniqueTagsReturn;
  };

  const columns = [
    {
      title: "Código",
      width: 100,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Nombre",
      width: 150,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      filters: uniqueValuesFinca(fincas)((i) => i),
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend"],
    },
    {
      title: "Tipo de sistemas",
      dataIndex: "sistema",
      key: "sistema",
      filters: uniqueValues(fincas)((i) => i),
      onFilter: (value, record) => record.sistema.indexOf(value) === 0,
    },
    {
      title: "Principal actividad económica",
      dataIndex: "secundario",
      key: "secundario",
    },
    { title: "Sector-Comunidad", dataIndex: "sector", key: "sector" },
    { title: "Parroquia", dataIndex: "parroquia", key: "parroquia" },
    {
      title: "Nombre Productor",
      dataIndex: "productor",
      key: "productor",
      width: 300,
    },
    { title: "Celular Productor", dataIndex: "celular", key: "celular" },
    { title: "Total cultivos", dataIndex: "cultivos", key: "cultivos" },
    { title: "Total pastizal", dataIndex: "pastizal", key: "pastizal" },
    { title: "Total Área de reforestación", dataIndex: "area", key: "area" },
    { title: "Bosque nativo", dataIndex: "bosque", key: "bosque" },
    { title: "Luzaras", dataIndex: "luzaras", key: "luzaras" },
    { title: "Total hectáreas", dataIndex: "hectareas", key: "hectareas" },
    {
      title: "Fecha Inicio",
      dataIndex: "fecha",
      key: "fecha",
      render: (fecha) => {
        return formatDate(fecha);
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 130,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editFinca(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => mapFincaShow(record)}
          >
            <AimOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteFinca(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="fincas">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Fincas</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addFinca}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={fincas}
        loading={loadingData}
        scroll={{ x: 2800 }}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen fincas"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
      >
        {modalContent}
      </ModalUser>
      <MapFinca
        title={modalTitleFinca}
        isVisibleModal={isVisibleModalFinca}
        setIsVisibleModal={setIsVisibleModalFinca}
        fincaData={fincaData}
        setFincaData={setFincaData}
        setReloadFincas={setReloadFincas}
        center={center}
      />
    </div>
  );
}
