import React, { useState, useEffect } from "react";
import { Select, Form, Col } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getAllSostenibilidadesApi } from "../../../../api/sostenibilidad.api";

import "./SelectAllSostenibilidad.scss";

const { Option } = Select;

export default function SelectAllSostenibilidad(props) {
  const { setSostenibilidadId } = props;
  const token = getAccessTokenApi();
  const [sostenibilidad, setSostenibilidad] = useState({});
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getAllSostenibilidadesApi(token).then((response) => {
      setSostenibilidad(response.sostenibilidades);
    });
    setLoadingData(false);
  }, [token, loadingData]);

  const handleSostenibilidadChange = (value) => {
    setSostenibilidadId(value);
  };

  return (
    <div className="select-ambito-form">
      <Select
        placeholder="Indicador de sostenibilidad"
        onChange={handleSostenibilidadChange}
        style={{ width: "100%" }}
      >
        {sostenibilidad && sostenibilidad.map
          ? sostenibilidad.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.codigo} - {item.name}
              </Option>
            ))
          : ""}
      </Select>
    </div>
  );
}
