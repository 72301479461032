import React from "react";
import { Modal, Descriptions, Table, Divider, Row, Col } from "antd";

export default function ModalSostenibilidad(props) {
  const { title, isVisibleModal, setIsVisibleModal, sostenibilidadFicha } =
    props;
  const handleCancel = () => {
    setIsVisibleModal(false);
  };

  const dataSource = [
    {
      key: "1",
      name: "A1",
      codigo: 32,
    },
    {
      key: "2",
      name: "B1",
      codigo: 42,
    },
  ];

  const columns = [
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <>
      <Modal
        title={title}
        centered={true}
        visible={isVisibleModal}
        onCancel={handleCancel}
        footer={false}
        animation={false}
        width={1200}
      >
        <Descriptions bordered>
          <Descriptions.Item label="Código">
            {sostenibilidadFicha.codigo}
          </Descriptions.Item>
          <Descriptions.Item label="Nombre">
            {sostenibilidadFicha.name}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">Grupo de pertenencia</Divider>
        <Descriptions bordered>
          <Descriptions.Item label="Código">
            {sostenibilidadFicha &&
            sostenibilidadFicha.criterio &&
            sostenibilidadFicha.criterio.atributo &&
            sostenibilidadFicha.criterio.atributo.ambito
              ? sostenibilidadFicha.criterio.atributo.ambito.codigo
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Ambito">
            {sostenibilidadFicha &&
            sostenibilidadFicha.criterio &&
            sostenibilidadFicha.criterio.atributo &&
            sostenibilidadFicha.criterio.atributo.ambito
              ? sostenibilidadFicha.criterio.atributo.ambito.name
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Código">
            {sostenibilidadFicha &&
            sostenibilidadFicha.criterio &&
            sostenibilidadFicha.criterio.atributo
              ? sostenibilidadFicha.criterio.atributo.codigo
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Atributo">
            {sostenibilidadFicha &&
            sostenibilidadFicha.criterio &&
            sostenibilidadFicha.criterio.atributo
              ? sostenibilidadFicha.criterio.atributo.name
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Código">
            {sostenibilidadFicha && sostenibilidadFicha.criterio
              ? sostenibilidadFicha.criterio.codigo
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Criterio de sostenibilidad Ecuador">
            {sostenibilidadFicha && sostenibilidadFicha.criterio
              ? sostenibilidadFicha.criterio.name
              : ""}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">Inidicadores asociados</Divider>
        <Table dataSource={dataSource} columns={columns} />
        <Divider orientation="left">1. Objetivo:</Divider>
        <p>{sostenibilidadFicha.objetivo}</p>
        <Divider orientation="left">2. Definición operacional:</Divider>
        <p>{sostenibilidadFicha.descripcion}</p>
        <Divider orientation="left">3. Método de cálculo:</Divider>
        <p>{sostenibilidadFicha.calculo}</p>
        <Divider orientation="left">4. Interpretación del resultado:</Divider>
        {sostenibilidadFicha.interpretacion
          ? JSON.parse(sostenibilidadFicha.interpretacion).map((item) => (
              <Row key={item.valor}>
                <Col span={16} className="colBorder">
                  {item.interpretacion}
                </Col>
                <Col span={8}>
                  <div className={item.color} width={200}>
                    {item.valor}
                  </div>
                </Col>
              </Row>
            ))
          : ""}
        <Divider orientation="left">5. Frecuencia de cálculo: </Divider>
        <p>{sostenibilidadFicha.frecuencia}</p>
        <Divider orientation="left">6. Limitantes: </Divider>
        <p>{sostenibilidadFicha.limitantes}</p>
        <Divider orientation="left">7. Requerimientos:</Divider>
        <p>{sostenibilidadFicha.requerimientos}</p>
        <Divider orientation="left">8. Línea base:</Divider>
        <p>{sostenibilidadFicha.base}</p>
        <Divider orientation="left">
          9. Responsable de generar los datos:
        </Divider>
        <p>{sostenibilidadFicha.responsable}</p>
      </Modal>
    </>
  );
}
