import React from "react";
import { Result } from "antd";

export default function Error404() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Oh no!, la página que buscas no existe."
      // extra={<Button type="primary">Back Home</Button>}
    />
  );
}
