import { basePath } from "./config";

export function getFincasApi(token, userId) {
  const url = `${basePath}/fincas/${userId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getAllFincasApi(token) {
  const url = `${basePath}/fincas-all`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getAllFincasIdApi(token, fincaId) {
  const url = `${basePath}/fincas-all-id/${fincaId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function UpdateFincaApi(token, finca, fincaId) {
  const url = `${basePath}/finca/update/${fincaId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(finca),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddFincaApi(token, finca) {
  const url = `${basePath}/finca/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(finca),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteFincaApi(token, fincaId) {
  const url = `${basePath}/finca/delete/${fincaId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getCountFincaApi(token) {
  const url = `${basePath}/finca-count`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFincasVariablesAvgApi(
  token,
  sostenibilidadId,
  fechaInicial,
  fechaFinal,
  fincaId
) {
  const url = `${basePath}/finca-variable-avg/${sostenibilidadId}/${fechaInicial}/${fechaFinal}/${fincaId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFincasAmbitoAvgApi(
  token,
  fechaInicial,
  fechaFinal,
  fincaId
) {
  const url = `${basePath}/finca-ambito-date-avg/${fechaInicial}/${fechaFinal}/${fincaId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFincasCriterioAvgApi(
  token,
  fechaInicial,
  fechaFinal,
  fincaId
) {
  const url = `${basePath}/finca-criterio-date-avg/${fechaInicial}/${fechaFinal}/${fincaId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFincasVariablesSecundarioAvgApi(
  token,
  sostenibilidadId,
  fechaInicial,
  fechaFinal,
  fincaId
) {
  const url = `${basePath}/finca-variable-secundario-avg/${sostenibilidadId}/${fechaInicial}/${fechaFinal}/${fincaId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFincasVariablesSecundarioDateAvgApi(
  token,
  variableId,
  fechaInicial,
  fechaFinal,
  fincaId
) {
  const url = `${basePath}/finca-variable-secundario-date-avg/${variableId}/${fechaInicial}/${fechaFinal}/${fincaId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
