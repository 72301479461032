import React, { useState, useEffect } from "react";
import { Select } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getAllFincasApi } from "../../../../api/finca.api";

import "./SelectFincaForm.scss";

const { Option } = Select;

export default function SelectFincaForm(props) {
  const { encuestaData, setEncuestaData, addSlag, disabled } = props;
  const [fincaData, setFincaData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [fincaId, setFincaId] = useState();

  useEffect(() => {
    getAllFincasApi(token).then((response) => {
      setFincaData(response.fincas);
      if (encuestaData) {
        setFincaId(encuestaData.fincaId);
      }

      //   if (addSlag === "add") {
      //     setFincaId(null);
      //   } else {
      //     setFincaId(encuestaData.fincaId);
      //   }
    });
    setLoadingData(false);
  }, [token, loadingData, addSlag, encuestaData]);

  const handleAtributoChange = (value) => {
    setFincaId(value);
    setEncuestaData({
      ...encuestaData,
      fincaId: value,
    });
  };

  return (
    <div className="select-finca-form">
      <Select
        placeholder="Seleccione la finca"
        value={fincaId}
        onChange={handleAtributoChange}
        disabled={disabled}
      >
        {fincaData && fincaData.map
          ? fincaData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))
          : ""}
      </Select>
    </div>
  );
}
