import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, notification, Space, DatePicker } from "antd";

import SelectFincaForm from "../../Finca/SelectFincaForm";

import { getAccessTokenApi, getUserData } from "../../../../api/auth";
import {
  UpdateEncuestaApi,
  AddEncuestaApi,
  DeleteEncuestaApi,
} from "../../../../api/encuesta.api";

import moment from "moment";

import "./EditEncuestaForm.scss";

export default function EditEncuestaForm(props) {
  const {
    encuesta,
    setIsVisibleModal,
    setReloadEncuestas,
    buttonName,
    addSlag,
  } = props;
  const [encuestaData, setEncuestaData] = useState({});
  const dateFormat = "YYYY/MM/DD";
  const token = getAccessTokenApi();
  const userData = getUserData(token);

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setEncuestaData({
      fecha: moment.now(),
      userId: userData.id,
      fincaId: null,
    });
  };

  useEffect(() => {
    if (encuesta) {
      setEncuestaData({
        id: encuesta.id,
        fecha: encuesta.fecha ? encuesta.fecha : moment.now(),
        userId: encuesta.userId,
        fincaId: encuesta.fincaId,
      });
    } else {
      setEncuestaData({
        id: null,
        userId: userData.id,
        fincaId: null,
        fecha: moment.now(),
      });
    }
  }, [encuesta]);

  const addUpdateEncuesta = (e) => {
    e.preventDefault();

    let encuestaUpdate = encuestaData;
    if (!encuestaUpdate.fincaId || !encuestaUpdate.fecha) {
      notification["error"]({
        message: "La finca y la fecha es obligatoria",
      });
      return;
    }

    if (addSlag === "add") {
      AddEncuestaApi(token, encuestaUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadEncuestas(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateEncuestaApi(token, encuestaUpdate, encuesta.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadEncuestas(true);
        setIsVisibleModal(false);
        setEncuestaData({
          ...encuestaData,
        });
      });
    } else {
      DeleteEncuestaApi(token, encuesta.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadEncuestas(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-encuesta-form">
      <form className="form-edit" onSubmit={addUpdateEncuesta}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <SelectFincaForm
                encuestaData={encuestaData}
                setEncuestaData={setEncuestaData}
                addSlag={addSlag}
                disabled={addSlag === "delete" ? true : false}
              ></SelectFincaForm>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Space direction="vertical">
                <DatePicker
                  placeholder="Fecha inicio evaluación"
                  defaultValue={moment(encuestaData.fecha)}
                  format={dateFormat}
                  disabled={addSlag === "delete" ? true : false}
                  onChange={(date, dateString) =>
                    setEncuestaData({ ...encuestaData, fecha: date })
                  }
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
