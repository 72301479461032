//Layout
import LayoutAdmin from "../layouts/LayoutAdmin";
import LayoutBasic from "../layouts/LayoutBasic";

//Admin pages
import AdminHome from "../pages/Admin";
import AdminSignIn from "../pages/Admin/SignIn";
import AdminFincas from "../pages/Admin/Fincas";
import AdminUsers from "../pages/Admin/Users";
import AdminSostenibilidad from "../pages/Admin/Indicadores/Sostenibilidad";
import AdminVariable from "../pages/Admin/Indicadores/Variable";
import AdminAmbito from "../pages/Admin/Indicadores/Ambito";
import AdminAtributo from "../pages/Admin/Indicadores/Atributo";
import AdminCriterio from "../pages/Admin/Indicadores/Criterio";
import AdminSecundario from "../pages/Admin/Indicadores/Secundario";
import AdminEncuesta from "../pages/Admin/Encuestas";
import AdminGeoposicion from "../pages/Admin/Mapa/Geoposicion";
import AdminZonas from "../pages/Admin/Mapa/Zonas";
import AdminIndicadores from "../pages/Admin/Reportes/Indicadores";
import AdminIndicadoresAmbito from "../pages/Admin/Reportes/Ambito";
import AdminIndicadoresCriterio from "../pages/Admin/Reportes/Criterio";

//Pages
// import Home from "../pages/Home";
// import Contact from "../pages/Contact";

//Other
import Error404 from "../pages/Error404";

const routes = [
  {
    path: "/",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/",
        component: AdminHome,
        exact: true,
      },
      {
        path: "/login",
        component: AdminSignIn,
        exact: true,
      },
      {
        path: "/users",
        component: AdminUsers,
        exact: true,
      },
      {
        path: "/fincas",
        component: AdminFincas,
        exact: true,
      },
      {
        path: "/encuestas",
        component: AdminEncuesta,
        exact: true,
      },
      {
        path: "/variables",
        component: AdminVariable,
        exact: true,
      },
      {
        path: "/mapa/geoposicion",
        component: AdminGeoposicion,
        exact: true,
      },
      {
        path: "/mapa/zonas",
        component: AdminZonas,
        exact: true,
      },
      {
        path: "/reportes/indicadores",
        component: AdminIndicadores,
        exact: true,
      },
      {
        path: "/reportes/ambito",
        component: AdminIndicadoresAmbito,
        exact: true,
      },
      {
        path: "/reportes/criterio",
        component: AdminIndicadoresCriterio,
        exact: true,
      },
      {
        path: "/indicadores/sostenibilidad",
        component: AdminSostenibilidad,
        exact: true,
      },
      {
        path: "/indicadores/ambito",
        component: AdminAmbito,
        exact: true,
      },
      {
        path: "/indicadores/atributo",
        component: AdminAtributo,
        exact: true,
      },
      {
        path: "/indicadores/criterio",
        component: AdminCriterio,
        exact: true,
      },
      {
        path: "/indicadores/secundarios",
        component: AdminSecundario,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
  // {
  //   path: "/",
  //   component: LayoutBasic,
  //   exact: false,
  //   routes: [
  //     {
  //       path: "/",
  //       component: Home,
  //       exact: true,
  //     },
  //     {
  //       path: "/contact",
  //       component: Contact,
  //       exact: true,
  //     },
  //     {
  //       component: Error404,
  //     },
  //   ],
  // },
];

export default routes;
