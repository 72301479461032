import { List, Empty, Card, Progress, Spin } from "antd";
import { countVariablesAmbitoApi } from "../../../../api/variable.api";
import { countVariableEncuestasAmbitoApi } from "../../../../api/variableEncuesta.api";
import { getAccessTokenApi } from "../../../../api/auth";
import { useState, useEffect } from "react";

export default function Ambito(props) {
  const { ambitoData, setAmbitoId, current, setCurrent, encuesta } = props;
  const [loadigData, setloadigData] = useState(true);
  const token = getAccessTokenApi();

  const handleAmbitoClick = (value) => {
    setAmbitoId(value);
    setCurrent(current + 1);
  };

  useEffect(() => {
    if (ambitoData) setloadigData(false);
  }, []);

  function CountVars({ ambitoId, encuestaId }) {
    const [contador, setContador] = useState(null);
    const [contadorEncuesta, setContadorEncuesta] = useState(null);
    useEffect(() => {
      let isMounted = true;
      countVariablesAmbitoApi(token, ambitoId).then((response) => {
        if (isMounted) setContador(response.count);
      });
      return () => {
        isMounted = false;
      };
    }, [ambitoId]);

    useEffect(() => {
      let isMounted = true;
      countVariableEncuestasAmbitoApi(token, encuestaId, ambitoId).then(
        (response) => {
          if (isMounted) setContadorEncuesta(response.count);
        }
      );
      return () => {
        isMounted = false;
      };
    }, [ambitoId, encuestaId]);

    if (contador === null || contadorEncuesta === null) {
      return <Spin size="small" />;
    }
    if (contadorEncuesta < contador) {
      const percentVal = (contadorEncuesta / contador) * 100;
      return (
        <>
          <Progress percent={percentVal.toFixed(0)} steps={3} />
          <br /> ({contadorEncuesta} de {contador})
        </>
      );
    }
    return (
      <>
        <Progress percent={100} steps={3} />
        <br /> ({contadorEncuesta} de {contador})
      </>
    );
  }

  return (
    <List
      dataSource={ambitoData}
      loading={loadigData}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No existen ámbitos"
          />
        ),
      }}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 6,
        xxl: 3,
      }}
      renderItem={(ambito) => (
        <List.Item>
          <Card
            hoverable
            title={ambito.codigo}
            onClick={() => {
              handleAmbitoClick(ambito.id);
            }}
          >
            <CountVars ambitoId={ambito.id} encuestaId={encuesta.id} />
            <br />
            <br />
            {ambito.name}
          </Card>
        </List.Item>
      )}
    />
  );
}
