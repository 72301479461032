import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";

import HomeOutlined from "@ant-design/icons/HomeOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import ToolOutlined from "@ant-design/icons/ToolOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import FileOutlined from "@ant-design/icons/FileOutlined";
import FolderOutlined from "@ant-design/icons/FolderOutlined";
import FundOutlined from "@ant-design/icons/FundOutlined";
import BarChartOutlined from "@ant-design/icons/BarChartOutlined";
import RadarChartOutlined from "@ant-design/icons/RadarChartOutlined";

import AimOutlined from "@ant-design/icons/AimOutlined";
import HeatMapOutlined from "@ant-design/icons/HeatMapOutlined";

import BankOutlined from "@ant-design/icons/BankOutlined";
import BarsOutlined from "@ant-design/icons/BarsOutlined";

import "./MenuSider.scss";

function MenuSider(props) {
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const { menuCollapsed, location } = props;

  return (
    <Sider className="admin-sider" theme="light" collapsed={menuCollapsed}>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item key="/">
          <Link to={"/"}>
            <HomeOutlined />
            <span className="nav-text">Principal</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/fincas">
          <Link to={"/fincas"}>
            <BankOutlined />
            <span className="nav-text">Fincas</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/encuestas">
          <Link to={"/encuestas"}>
            <BarsOutlined />
            <span className="nav-text">Registro de datos</span>
          </Link>
        </Menu.Item>
        <SubMenu key="sub1" icon={<ToolOutlined />} title="Parámetros">
          <SubMenu key="sub5" icon={<FolderOutlined />} title="Categorización">
            {/* indicadores */}
            <Menu.Item key="/indicadores/ambito">
              <Link to={"/indicadores/ambito"}>
                <span className="nav-text">Ámbito</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/indicadores/atributo">
              <Link to={"/indicadores/atributo"}>
                <span className="nav-text">Atributo</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/indicadores/criterio">
              <Link to={"/indicadores/criterio"}>
                <span className="nav-text">Criterio</span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="/indicadores/secundarios">
              <Link to={"/indicadores/secundarios"}>
                <span className="nav-text">Secundarios</span>
              </Link>
            </Menu.Item> */}
          </SubMenu>
          <SubMenu key="sub6" icon={<FolderOutlined />} title="Indicadores">
            {/* indicadores */}
            <Menu.Item key="/indicadores/sostenibilidad">
              <Link to={"/indicadores/sostenibilidad"}>
                <span className="nav-text">Sostenibilidad</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/variables">
              <Link to={"/variables"}>
                <span className="nav-text">Secundarios</span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="/indicadores/secundarios">
              <Link to={"/indicadores/secundarios"}>
                <span className="nav-text">Secundarios</span>
              </Link>
            </Menu.Item> */}
          </SubMenu>
        </SubMenu>
        <SubMenu key="sub2" icon={<AimOutlined />} title="Mapa">
          <Menu.Item key="/mapa/geoposicion">
            <Link to={"/mapa/geoposicion"}>
              <AimOutlined />
              <span className="nav-text">Geoposición</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/mapa/zonas">
            <Link to={"/mapa/zonas"}>
              <HeatMapOutlined />
              <span className="nav-text">Zonas</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub3" icon={<FundOutlined />} title="Reportes">
          <Menu.Item key="/reportes/ambito">
            <Link to={"/reportes/ambito"}>
              <RadarChartOutlined />
              <span className="nav-text">Ámbito</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/reportes/criterio">
            <Link to={"/reportes/criterio"}>
              <RadarChartOutlined />
              <span className="nav-text">Criterio de sostenibilidad</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/reportes/indicadores">
            <Link to={"/reportes/indicadores"}>
              <BarChartOutlined />
              <span className="nav-text">Indicadores</span>
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="/reporte2">
            <Link to={""}>
              <PieChartOutlined />
              <span className="nav-text">Reporte2</span>
            </Link>
          </Menu.Item> */}
        </SubMenu>
        <SubMenu key="sub4" icon={<SettingOutlined />} title="Administración">
          <Menu.Item key="/users">
            <Link to={"/users"}>
              <UserOutlined />
              <span className="nav-text">Usuarios</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
}

export default withRouter(MenuSider);
