import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Empty, Breadcrumb } from "antd";
import { getAccessTokenApi } from "../../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";

import "./Variable.scss";

import ModalUser from "../../../../components/Admin/ModalUser";
import EditVariableForm from "../../../../components/Admin/Variable/EditVariableForm";
import { getVariablesApi } from "../../../../api/variable.api";

import ModalSostenibilidad from "../../../../components/Admin/Sostenibilidad/ModalSostenibilidad";

export default function Variable() {
  const [variables, setVariables] = useState([]);
  const token = getAccessTokenApi();
  const [reloadVariables, setReloadVariables] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [isVisibleModalFicha, setIsVisibleModalFicha] = useState(false);

  useEffect(() => {
    getVariablesApi(token).then((response) => {
      setVariables(response.variables);
      setLoadingData(false);
    });

    setReloadVariables(false);
  }, [token, reloadVariables]);

  const columns = [
    {
      title: "Código",
      width: 40,
      dataIndex: "codigo",
      key: "codigo",
      fixed: "left",
    },
    {
      title: "Nombre",
      width: 80,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "indicador de sostenibilidad",
      width: 100,
      dataIndex: ["sostenibilidad", "name"],
      key: "sostenibilidadId",
    },
    {
      title: "Ámbito",
      width: 100,
      dataIndex: ["sostenibilidad", "criterio", "atributo", "ambito", "name"],
      key: "sostenibilidad.criterio.atributo.ambitoId",
    },
    {
      title: "Atributo",
      width: 100,
      dataIndex: ["sostenibilidad", "criterio", "atributo", "name"],
      key: "sostenibilidad.criterio.atributoId",
    },
    {
      title: "Criterio de sostenibilidad",
      width: 100,
      dataIndex: ["sostenibilidad", "criterio", "name"],
      key: "sostenibilidad.criterioId",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editSostenibilidad(record)}
          >
            <EditOutlined />
          </Button>
          {/* <Button
            size="small"
            type="primary"
            onClick={() => fichaSostenibilidad(record)}
          >
            <EyeOutlined />
          </Button> */}
          <Button
            size="small"
            type="danger"
            onClick={() => deleteSostenibilidad(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const addSostenibilidad = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nueva variable");
    setmodalContent(
      <EditVariableForm
        variable={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadVariables={setReloadVariables}
        buttonName="Agregar variable"
        addSlag={"add"}
      />
    );
  };
  const editSostenibilidad = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar variable");
    setmodalContent(
      <EditVariableForm
        variable={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadVariables={setReloadVariables}
        buttonName="Editar variable"
        addSlag={"edit"}
      />
    );
  };

  const deleteSostenibilidad = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar variable");
    setmodalContent(
      <EditVariableForm
        variable={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadVariables={setReloadVariables}
        buttonName="Eliminar variable"
        addSlag={"delete"}
      />
    );
  };
  // const fichaSostenibilidad = (record) => {
  //   setIsVisibleModalFicha(true);
  //   setSostenibilidadFicha(record);
  //   setModalTitleFicha("Ficha indicador de sostenibilidad");
  // };
  return (
    <div className="fincas">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Indicadores</Breadcrumb.Item>
        <Breadcrumb.Item>Indicadores secundarios</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addSostenibilidad}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={variables}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen variables"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
      >
        {modalContent}
      </ModalUser>

      {/* <ModalSostenibilidad
        title={modalTitleFicha}
        isVisibleModal={isVisibleModalFicha}
        setIsVisibleModal={setIsVisibleModalFicha}
        sostenibilidadFicha={sostenibilidadFicha}
      /> */}
    </div>
  );
}
