import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Empty, Breadcrumb } from "antd";
import { getAccessTokenApi } from "../../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./Criterio.scss";

import ModalUser from "../../../../components/Admin/ModalUser";
import EditCriterioForm from "../../../../components/Admin/Criterio/EditCriterioForm";
import { getAllCriteriosApi } from "../../../../api/criterio.api";

export default function Criterio() {
  const [criterios, setCriterios] = useState([]);
  const token = getAccessTokenApi();
  const [reloadCriterios, setReloadCriterios] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getAllCriteriosApi(token).then((response) => {
      setCriterios(response.criterios);
      setLoadingData(false);
    });

    setReloadCriterios(false);
  }, [token, reloadCriterios]);

  const columns = [
    {
      title: "Código",
      width: 40,
      dataIndex: "codigo",
      key: "codigo",
      fixed: "left",
    },
    {
      title: "Nombre",
      width: 80,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Ámbito",
      width: 100,
      dataIndex: ["atributo", "ambito", "name"],
      key: "atributo.ambitoId",
    },
    {
      title: "Atributo",
      width: 100,
      dataIndex: ["atributo", "name"],
      key: "atributoId",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editCriterio(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteCriterio(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const addCriterio = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo criterio");
    setmodalContent(
      <EditCriterioForm
        criterio={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCriterios={setReloadCriterios}
        buttonName="Agregar criterio"
        addSlag={"add"}
      />
    );
  };
  const editCriterio = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar criterio");
    setmodalContent(
      <EditCriterioForm
        criterio={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCriterios={setReloadCriterios}
        buttonName="Editar criterio"
        addSlag={"edit"}
      />
    );
  };

  const deleteCriterio = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar criterio");
    setmodalContent(
      <EditCriterioForm
        criterio={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCriterios={setReloadCriterios}
        buttonName="Eliminar criterio"
        addSlag={"delete"}
      />
    );
  };

  return (
    <div className="criterios">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Inidicadores</Breadcrumb.Item>
        <Breadcrumb.Item>Criterio</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addCriterio}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={criterios}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen criterios"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
