import { Row, Col, Input, Form, Button, notification } from "antd";
import {
  UpdateVariableEncuestaApi,
  AddVariableEncuestaApi,
} from "../../../../api/variableEncuesta.api";
import { getAccessTokenApi } from "../../../../api/auth";
import { useState, useEffect } from "react";
import moment from "moment";

export default function Variable(props) {
  const {
    variableData,
    variableEncuestaData,
    setVariableEncuestaData,
    current,
    setCurrent,
    encuesta,
  } = props;
  const [variableEncuesta, setVariableEncuesta] = useState({});
  const [resultadoView, setResultadoView] = useState();
  const [criticidadView, setCriticidadView] = useState();
  const token = getAccessTokenApi();
  const [form] = Form.useForm();

  useEffect(() => {
    if (variableEncuestaData) {
      setResultadoView(variableEncuestaData.resultado);
      setCriticidadView(variableEncuestaData.normalizado);
      form.setFieldsValue({
        var1: variableEncuestaData.var1,
        var2: variableEncuestaData.var2,
        var3: variableEncuestaData.var3,
        var4: variableEncuestaData.var4,
        var5: variableEncuestaData.var5,
        var6: variableEncuestaData.var6,
        var7: variableEncuestaData.var7,
        var8: variableEncuestaData.var8,
        resultado: variableEncuestaData.resultado,
        normalizado: variableEncuestaData.normalizado,
      });
    } else {
      setResultadoView(null);
      setCriticidadView(null);
      form.setFieldsValue({
        var1: null,
        var2: null,
        var3: null,
        var4: null,
        var5: null,
        var6: null,
        var7: null,
        var8: null,
        resultado: null,
        normalizado: null,
      });
    }
  }, [variableEncuestaData]);

  const onFinish = (values) => {
    let variableEncuestaUpdate = variableEncuesta;
    variableEncuestaUpdate.variableId = variableData.id;
    variableEncuestaUpdate.encuestaId = encuesta.id;
    variableEncuestaUpdate.fecha = moment.now();

    const formula = variableData.formula;
    const criticidad1 = variableData.criticidad1.split(":");
    const criticidad2 = variableData.criticidad2.split(":");
    const criticidad3 = variableData.criticidad3.split(":");
    const criticidad4 = variableData.criticidad4.split(":");
    const criticidad5 = variableData.criticidad5.split(":");

    var var1 = null;
    var var2 = null;
    var var3 = null;
    var var4 = null;
    var var5 = null;
    var var6 = null;
    var var7 = null;
    var var8 = null;

    if (
      variableEncuesta.var1 ||
      (variableEncuestaData && variableEncuestaData.var1)
    ) {
      var1 = variableEncuesta.var1
        ? variableEncuesta.var1
        : variableEncuestaData.var1;
      variableEncuestaUpdate.var1 = var1;
    }
    if (
      variableEncuesta.var2 ||
      (variableEncuestaData && variableEncuestaData.var2)
    ) {
      var2 = variableEncuesta.var2
        ? variableEncuesta.var2
        : variableEncuestaData.var2;
      variableEncuestaUpdate.var2 = var2;
    }
    if (
      variableEncuesta.var3 ||
      (variableEncuestaData && variableEncuestaData.var3)
    ) {
      var3 = variableEncuesta.var3
        ? variableEncuesta.var3
        : variableEncuestaData.var3;
      variableEncuestaUpdate.var3 = var3;
    }
    if (
      variableEncuesta.var4 ||
      (variableEncuestaData && variableEncuestaData.var4)
    ) {
      var4 = variableEncuesta.var4
        ? variableEncuesta.var4
        : variableEncuestaData.var4;
      variableEncuestaUpdate.var4 = var4;
    }
    if (
      variableEncuesta.var5 ||
      (variableEncuestaData && variableEncuestaData.var5)
    ) {
      var5 = variableEncuesta.var5
        ? variableEncuesta.var5
        : variableEncuestaData.var5;
      variableEncuestaUpdate.var5 = var5;
    }
    if (
      variableEncuesta.var6 ||
      (variableEncuestaData && variableEncuestaData.var6)
    ) {
      var6 = variableEncuesta.var6
        ? variableEncuesta.var6
        : variableEncuestaData.var6;
      variableEncuestaUpdate.var6 = var6;
    }
    if (
      variableEncuesta.var7 ||
      (variableEncuestaData && variableEncuestaData.var7)
    ) {
      var7 = variableEncuesta.var7
        ? variableEncuesta.var7
        : variableEncuestaData.var7;
      variableEncuestaUpdate.var7 = var7;
    }
    if (
      variableEncuesta.var8 ||
      (variableEncuestaData && variableEncuestaData.var8)
    ) {
      var8 = variableEncuesta.var8
        ? variableEncuesta.var8
        : variableEncuestaData.var8;
      variableEncuestaUpdate.var8 = var8;
    }

    var resultado = eval(formula);
    variableEncuestaUpdate.resultado = resultado;
    setResultadoView(resultado);
    var criticidad = 1;

    if (criticidad1[1] === criticidad1[0]) {
      criticidad = resultado;
    } else if (criticidad2[1] < criticidad3[1]) {
      if (resultado <= criticidad1[1]) {
        criticidad = 1;
      } else if (resultado > criticidad2[0] && resultado <= criticidad2[1]) {
        criticidad = 2;
      } else if (resultado > criticidad3[0] && resultado <= criticidad3[1]) {
        criticidad = 3;
      } else if (resultado > criticidad4[0] && resultado <= criticidad4[1]) {
        criticidad = 4;
      } else if (resultado > criticidad5[0]) {
        criticidad = 5;
      }
    } else {
      if (resultado < criticidad5[0]) {
        criticidad = 5;
      } else if (resultado < criticidad4[0] && resultado >= criticidad4[1]) {
        criticidad = 4;
      } else if (resultado < criticidad3[0] && resultado >= criticidad3[1]) {
        criticidad = 3;
      } else if (resultado < criticidad2[0] && resultado >= criticidad2[1]) {
        criticidad = 2;
      } else if (resultado >= criticidad1[1]) {
        criticidad = 1;
      }
    }

    variableEncuestaUpdate.normalizado = criticidad;

    setCriticidadView(criticidad);

    if (variableEncuestaData) {
      variableEncuestaUpdate.id = variableEncuestaData.id;
      UpdateVariableEncuestaApi(
        token,
        variableEncuestaUpdate,
        variableEncuestaData.id
      ).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setVariableEncuestaData(variableEncuestaUpdate);
      });
    } else {
      AddVariableEncuestaApi(token, variableEncuestaUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setVariableEncuestaData(result.variable);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <Form
      form={form}
      name="basic"
      initialValues={{ remember: false }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <h3>{variableData.codigo}</h3>
      <h4>{variableData.name}</h4>
      <p>{variableData.descripcion}</p>
      {variableData.var1 && (
        <Row>
          <Col className="text-left" span={12}>
            {variableData.var1}
          </Col>
          <Col span={6}>
            <Form.Item
              name="var1"
              initialValue={
                variableEncuestaData ? variableEncuestaData.var1 : null
              }
              rules={[
                {
                  required: true,
                  message: "Este valor es obligatorio",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  setVariableEncuesta({
                    ...variableEncuesta,
                    var1: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            {variableData.tipo1 === "0" ? "Número" : "Normalizado"}
          </Col>
        </Row>
      )}
      {variableData.var2 && (
        <Row>
          <Col className="text-left" span={12}>
            {variableData.var2}
          </Col>
          <Col span={6}>
            <Form.Item
              name="var2"
              initialValue={
                variableEncuestaData ? variableEncuestaData.var2 : null
              }
              rules={[
                {
                  required: true,
                  message: "Este valor es obligatorio",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  setVariableEncuesta({
                    ...variableEncuesta,
                    var2: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            {variableData.tipo2 === "0" ? "Número" : "Normalizado"}
          </Col>
        </Row>
      )}
      {variableData.var3 && (
        <Row>
          <Col className="text-left" span={12}>
            {variableData.var3}
          </Col>
          <Col span={6}>
            <Form.Item
              name="var3"
              initialValue={
                variableEncuestaData ? variableEncuestaData.var3 : null
              }
              rules={[
                {
                  required: true,
                  message: "Este valor es obligatorio",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  setVariableEncuesta({
                    ...variableEncuesta,
                    var3: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            {variableData.tipo3 === "0" ? "Número" : "Normalizado"}
          </Col>
        </Row>
      )}
      {variableData.var4 && (
        <Row
          className={
            !variableData.var4
              ? "hide margin-bottom-minimun"
              : "show margin-bottom-minimun"
          }
        >
          <Col className="text-left" span={12}>
            {variableData.var4}
          </Col>
          <Col span={6}>
            <Form.Item
              name="var4"
              initialValue={
                variableEncuestaData ? variableEncuestaData.var4 : null
              }
              rules={[
                {
                  required: true,
                  message: "Este valor es obligatorio",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  setVariableEncuesta({
                    ...variableEncuesta,
                    var4: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            {variableData.tipo4 === "0" ? "Número" : "Normalizado"}
          </Col>
        </Row>
      )}
      {variableData.var5 && (
        <Row>
          <Col className="text-left" span={12}>
            {variableData.var5}
          </Col>
          <Col span={6}>
            <Form.Item
              name="var5"
              initialValue={
                variableEncuestaData ? variableEncuestaData.var5 : null
              }
              rules={[
                {
                  required: true,
                  message: "Este valor es obligatorio",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  setVariableEncuesta({
                    ...variableEncuesta,
                    var5: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            {variableData.tipo5 === "0" ? "Número" : "Normalizado"}
          </Col>
        </Row>
      )}
      {variableData.var6 && (
        <Row>
          <Col className="text-left" span={12}>
            {variableData.var6}
          </Col>
          <Col span={6}>
            <Form.Item
              name="var6"
              initialValue={
                variableEncuestaData ? variableEncuestaData.var6 : null
              }
              rules={[
                {
                  required: true,
                  message: "Este valor es obligatorio",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  setVariableEncuesta({
                    ...variableEncuesta,
                    var6: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            {variableData.tipo6 === "0" ? "Número" : "Normalizado"}
          </Col>
        </Row>
      )}
      {variableData.var7 && (
        <Row>
          <Col className="text-left" span={12}>
            {variableData.var7}
          </Col>
          <Col span={6}>
            <Form.Item
              name="var7"
              initialValue={
                variableEncuestaData ? variableEncuestaData.var7 : null
              }
              rules={[
                {
                  required: true,
                  message: "Este valor es obligatorio",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  setVariableEncuesta({
                    ...variableEncuesta,
                    var7: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            {variableData.tipo7 === "0" ? "Número" : "Normalizado"}
          </Col>
        </Row>
      )}
      {variableData.var8 && (
        <Row>
          <Col className="text-left" span={12}>
            {variableData.var8}
          </Col>
          <Col span={6}>
            <Form.Item
              name="var8"
              initialValue={
                variableEncuestaData ? variableEncuestaData.var8 : null
              }
              rules={[
                {
                  required: true,
                  message: "Este valor es obligatorio",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  setVariableEncuesta({
                    ...variableEncuesta,
                    var8: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            {variableData.tipo8 === "0" ? "Número" : "Normalizado"}
          </Col>
        </Row>
      )}
      <br />
      <Row>
        <Col className="text-left" span={18}>
          <b>Resultado ({variableData ? variableData.formula : null}):</b>
        </Col>
        <Col span={6}>{resultadoView}</Col>
      </Row>
      <Row>
        <Col className="text-left" span={18}>
          <b>Criticidad:</b>
        </Col>
        <Col span={6}>{criticidadView}</Col>
      </Row>
      <br />
      <Form.Item className="text-left">
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
}
