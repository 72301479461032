import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import SelectAmbitoAtributo from "../../Atributo/SelectAmbitoAtributo";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateCriterioApi,
  AddCriterioApi,
  DeleteCriterioApi,
} from "../../../../api/criterio.api";

import "./EditCriterioForm.scss";

export default function EditCriterioForm(props) {
  const {
    criterio,
    setIsVisibleModal,
    setReloadCriterios,
    buttonName,
    addSlag,
  } = props;
  const [criterioData, setCriterioData] = useState({});
  const [ambitoId, setambitoId] = useState();
  const [atributoId, setAtributoId] = useState();

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setCriterioData({
      codigo: "",
      name: "",
      atributoId: null,
    });

    setambitoId(null);
    setAtributoId(null);
  };

  useEffect(() => {
    if (criterio) {
      setCriterioData({
        id: criterio.id,
        codigo: criterio.codigo,
        name: criterio.name,
        atributoId: criterio.atributoId,
      });
      setambitoId(criterio.atributo.ambitoId);
      setAtributoId(criterio.atributoId);
    } else {
      setCriterioData({
        id: null,
        codigo: "",
        name: "",
        atributoId: null,
      });
      setambitoId(null);
      setAtributoId(null);
    }
  }, [criterio]);

  const addUpdateCriterio = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let criterioUpdate = criterioData;
    criterioUpdate.atributoId = atributoId;
    if (!atributoId && addSlag !== "delete") {
      notification["error"]({
        message: "El atributo es obligatorio",
      });
      return;
    }

    if (!criterioUpdate.codigo || !criterioUpdate.name) {
      notification["error"]({
        message: "El código y nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddCriterioApi(token, criterioUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCriterios(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateCriterioApi(token, criterioUpdate, criterio.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCriterios(true);
        setIsVisibleModal(false);
        // setCriterioData({
        //   ...criterioData,
        // });
      });
    } else {
      DeleteCriterioApi(token, criterio.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCriterios(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-criterio-form">
      <form className="form-edit" onSubmit={addUpdateCriterio}>
        <Row gutter={24}>
          <SelectAmbitoAtributo
            addSlag={addSlag}
            ambitoId={ambitoId}
            setAmbitoId={setambitoId}
            atributoId={atributoId}
            setAtributoId={setAtributoId}
          ></SelectAmbitoAtributo>

          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Código"
                value={criterioData.codigo}
                onChange={(e) =>
                  setCriterioData({ ...criterioData, codigo: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={criterioData.name}
                onChange={(e) =>
                  setCriterioData({ ...criterioData, name: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
