import React, { useState, useEffect } from "react";
import { Select, Form, Col } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getAtributosApi } from "../../../../api/atributo.api";
import { getAmbitosApi } from "../../../../api/ambito.api";

import "./SelectAmbitoAtributo.scss";

const { Option } = Select;

export default function SelectAmbitoAtributo(props) {
  const { addSlag, ambitoId, setAmbitoId, atributoId, setAtributoId } = props;

  const token = getAccessTokenApi();
  const [ambitoData, setAmbitoData] = useState({});
  const [atributoData, setAtributoData] = useState({});
  const [disableAtributo, setdisableAtributo] = useState();

  const handleAmbitoChange = (value) => {
    setAmbitoId(value);
    setAtributoId(null);
    getAtributosApi(token, value).then((response) => {
      setAtributoData(response.ambitos);
      setdisableAtributo(false);
    });
  };

  const onAtributoChange = (value) => {
    setAtributoId(value);
  };

  useEffect(() => {
    getAmbitosApi(token).then((response) => {
      setAmbitoData(response.ambitos);
    });
  }, [token]);

  useEffect(() => {
    if (ambitoId) {
      getAtributosApi(token, ambitoId).then((response) => {
        setAtributoData(response.atributos);
        setdisableAtributo(false);
      });
    } else {
      setdisableAtributo(true);
    }
  }, [token, ambitoId]);

  return (
    <>
      <Col span={24}>
        <Form.Item>
          <Select
            placeholder="Seleccione el ambito"
            value={ambitoId}
            onChange={handleAmbitoChange}
            disabled={addSlag === "delete" ? true : false}
          >
            {ambitoData && ambitoData.map
              ? ambitoData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.codigo} - {item.name}
                  </Option>
                ))
              : ""}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <Select
            placeholder="Seleccione el atributo"
            value={atributoId}
            onChange={onAtributoChange}
            disabled={addSlag === "delete" ? true : disableAtributo}
          >
            {atributoData && atributoData.map
              ? atributoData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.codigo} - {item.name}
                  </Option>
                ))
              : ""}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}
