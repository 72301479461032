import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import SelectAmbitoForm from "../../Ambito/SelectAmbitoForm";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateAtributoApi,
  AddAtributoApi,
  DeleteAtributoApi,
} from "../../../../api/atributo.api";

import "./EditAtributoForm.scss";

export default function EditAtributoForm(props) {
  const {
    atributo,
    setIsVisibleModal,
    setReloadAtributos,
    buttonName,
    addSlag,
  } = props;
  const [atributoData, setAtributoData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setAtributoData({
      codigo: "",
      name: "",
      ambitoId: null,
    });
  };

  useEffect(() => {
    if (atributo) {
      setAtributoData({
        id: atributo.id,
        codigo: atributo.codigo,
        name: atributo.name,
        ambitoId: atributo.ambitoId,
      });
    } else {
      setAtributoData({
        id: null,
        codigo: "",
        name: "",
        ambitoId: null,
      });
    }
  }, [atributo]);

  const addUpdateAtributo = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let atributoUpdate = atributoData;
    if (!atributoUpdate.ambitoId && addSlag !== "delete") {
      notification["error"]({
        message: "El ámbito es obligatorio",
      });
      return;
    }

    if (!atributoUpdate.codigo || !atributoUpdate.name) {
      notification["error"]({
        message: "El código y nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddAtributoApi(token, atributoUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadAtributos(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateAtributoApi(token, atributoUpdate, atributo.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadAtributos(true);
        setIsVisibleModal(false);
        // setAtributoData({
        //   ...atributoData,
        // });
      });
    } else {
      DeleteAtributoApi(token, atributo.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadAtributos(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-atributo-form">
      <form className="form-edit" onSubmit={addUpdateAtributo}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <SelectAmbitoForm
                setAtributoData={setAtributoData}
                atributoData={atributoData}
                addSlag={addSlag}
                loadingData={loadingData}
                setLoadingData={setLoadingData}
                disabled={addSlag === "delete" ? true : false}
              ></SelectAmbitoForm>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Código"
                value={atributoData.codigo}
                onChange={(e) =>
                  setAtributoData({ ...atributoData, codigo: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={atributoData.name}
                onChange={(e) =>
                  setAtributoData({ ...atributoData, name: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
