import React, { useState, useEffect } from "react";
import { Select } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getAmbitosApi } from "../../../../api/ambito.api";

import "./SelectAmbitoForm.scss";

const { Option } = Select;

export default function SelectAmbitoForm(props) {
  const { atributoData, setAtributoData, addSlag, disabled } = props;
  const [ambitoData, setAmbitoData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [ambitoId, setAmbitoId] = useState();

  useEffect(() => {
    getAmbitosApi(token).then((response) => {
      setAmbitoData(response.ambitos);
      setAmbitoId(atributoData.ambitoId);
      //   if (addSlag === "add") {
      //     setAmbitoId(null);
      //   } else {
      //     setAmbitoId(atributoData.ambitoId);
      //   }
    });
    setLoadingData(false);
  }, [token, loadingData, addSlag, atributoData]);

  const handleAtributoChange = (value) => {
    setAmbitoId(value);
    setAtributoData({
      ...atributoData,
      ambitoId: value,
    });
  };

  return (
    <div className="select-ambito-form">
      <Select
        placeholder="Seleccione el ámbito"
        value={ambitoId}
        onChange={handleAtributoChange}
        disabled={disabled}
      >
        {ambitoData && ambitoData.map
          ? ambitoData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.codigo} - {item.name}
              </Option>
            ))
          : ""}
      </Select>
    </div>
  );
}
