import { basePath } from "./config";

export function getVariableEncuestasApi(token) {
  const url = `${basePath}/variableencuestas`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getVariableEncuestasByEncuestaApi(
  token,
  encuestaId,
  variableId
) {
  const url = `${basePath}/variableencuestas-encuesta/${encuestaId}/${variableId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function UpdateVariableEncuestaApi(
  token,
  variableEncuesta,
  variableEncuestaId
) {
  const url = `${basePath}/variableencuesta/update/${variableEncuestaId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(variableEncuesta),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddVariableEncuestaApi(token, variableEncuesta) {
  const url = `${basePath}/variableencuesta/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(variableEncuesta),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteVariableEncuestaApi(token, variableEncuestaId) {
  const url = `${basePath}/variableencuesta/delete/${variableEncuestaId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function countVariableEncuestasAmbitoApi(token, encuestaId, ambitoId) {
  const url = `${basePath}/variableencuestas-ambito/${encuestaId}/${ambitoId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function countVariableEncuestasApi(token, encuestaId) {
  const url = `${basePath}/variableencuestas-count/${encuestaId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function countVariableEncuestasAtributoApi(
  token,
  encuestaId,
  atributoId
) {
  const url = `${basePath}/variableencuestas-atributo/${encuestaId}/${atributoId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function countVariableEncuestasCriterioApi(
  token,
  encuestaId,
  criterioId
) {
  const url = `${basePath}/variableencuestas-criterio/${encuestaId}/${criterioId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function countVariableEncuestasSostenibilidaApi(
  token,
  encuestaId,
  sostenibilidadId
) {
  const url = `${basePath}/variableencuestas-sostenibilidad/${encuestaId}/${sostenibilidadId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function countVariableEncuestasVariableApi(
  token,
  encuestaId,
  variableId
) {
  const url = `${basePath}/variableencuestas-variable/${encuestaId}/${variableId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
