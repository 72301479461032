import React, { useState, useEffect } from "react";
import { Select, Form, Col } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getAtributosApi } from "../../../../api/atributo.api";
import { getAmbitosApi } from "../../../../api/ambito.api";
import { getCriteriosApi } from "../../../../api/criterio.api";
import { getSostenibilidadesApi } from "../../../../api/sostenibilidad.api";

import "./SelectVariable.scss";

const { Option } = Select;

export default function SelectVariable(props) {
  const {
    addSlag,
    ambitoId,
    setAmbitoId,
    atributoId,
    setAtributoId,
    criterioId,
    setCriterioId,
    sostenibilidadId,
    setSostenibilidadId,
  } = props;

  const token = getAccessTokenApi();
  const [ambitoData, setAmbitoData] = useState({});
  const [atributoData, setAtributoData] = useState({});
  const [disableAtributo, setdisableAtributo] = useState();
  const [criterioData, setCriterioData] = useState({});
  const [disableCriterio, setdisableCriterio] = useState();

  const [sostenibilidadData, setSostenibilidadData] = useState({});
  const [disableSostenibilidad, setDisableSostenibilidad] = useState();

  const handleAmbitoChange = (value) => {
    setAmbitoId(value);
    setAtributoId(null);
    setCriterioId(null);
    setSostenibilidadId(null);
    getAtributosApi(token, value).then((response) => {
      setAtributoData(response.ambitos);
      setdisableAtributo(false);
    });
  };

  const handleAtributoChange = (value) => {
    setAtributoId(value);
    setCriterioId(null);
    setSostenibilidadId(null);
    getCriteriosApi(token, value).then((response) => {
      setCriterioData(response.criterios);
      setdisableCriterio(false);
    });
  };

  const handleCriterioChange = (value) => {
    setCriterioId(value);
    setSostenibilidadId(null);
    getSostenibilidadesApi(token, value).then((response) => {
      setSostenibilidadData(response.sostenibilidades);
      setDisableSostenibilidad(false);
    });
  };

  const onSostenibilidadChange = (value) => {
    setSostenibilidadId(value);
  };

  useEffect(() => {
    getAmbitosApi(token).then((response) => {
      setAmbitoData(response.ambitos);
    });
  }, [token]);

  useEffect(() => {
    if (ambitoId) {
      getAtributosApi(token, ambitoId).then((response) => {
        setAtributoData(response.atributos);
        setdisableAtributo(false);
      });
    } else {
      setdisableAtributo(true);
    }
  }, [token, ambitoId]);

  useEffect(() => {
    if (atributoId) {
      getCriteriosApi(token, atributoId).then((response) => {
        setCriterioData(response.criterios);
        setdisableCriterio(false);
      });
    } else {
      setdisableCriterio(true);
    }
  }, [token, atributoId]);

  useEffect(() => {
    if (criterioId) {
      getSostenibilidadesApi(token, criterioId).then((response) => {
        setSostenibilidadData(response.sostenibilidades);
        setDisableSostenibilidad(false);
      });
    } else {
      setDisableSostenibilidad(true);
    }
  }, [token, criterioId]);

  return (
    <>
      <Col span={24}>
        <Form.Item>
          <Select
            placeholder="Seleccione el ambito"
            value={ambitoId}
            onChange={handleAmbitoChange}
            disabled={addSlag === "delete" ? true : false}
          >
            {ambitoData && ambitoData.map
              ? ambitoData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.codigo} - {item.name}
                  </Option>
                ))
              : ""}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <Select
            placeholder="Seleccione el atributo"
            value={atributoId}
            onChange={handleAtributoChange}
            disabled={addSlag === "delete" ? true : disableAtributo}
          >
            {atributoData && atributoData.map
              ? atributoData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.codigo} - {item.name}
                  </Option>
                ))
              : ""}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <Select
            placeholder="Seleccione el criterio de sostenibilidad"
            value={criterioId}
            onChange={handleCriterioChange}
            disabled={addSlag === "delete" ? true : disableCriterio}
          >
            {criterioData && criterioData.map
              ? criterioData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.codigo} - {item.name}
                  </Option>
                ))
              : ""}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <Select
            placeholder="Seleccione el indicador de sostenibilidad"
            value={sostenibilidadId}
            onChange={onSostenibilidadChange}
            disabled={addSlag === "delete" ? true : disableSostenibilidad}
          >
            {sostenibilidadData && sostenibilidadData.map
              ? sostenibilidadData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.codigo} - {item.name}
                  </Option>
                ))
              : ""}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}
