import React, { useState, useEffect } from "react";
import { Modal, Steps, Button } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import "./VariableEncuesta.scss";
import Ambito from "./Ambito";
import Atributo from "./Atributo";
import { getAtributosApi } from "../../../../api/atributo.api";
import Criterio from "./Criterio";
import { getCriteriosApi } from "../../../../api/criterio.api";
import { getSostenibilidadesApi } from "../../../../api/sostenibilidad.api";
import Sostenibilidad from "./Sostenibilidad";
import { getVariablesByIndicadorApi } from "../../../../api/variable.api";
import { getVariableEncuestasByEncuestaApi } from "../../../../api/variableEncuesta.api";
import Variable from "./Variable";
import Secundarios from "./Secundarios";

const { Step } = Steps;

export default function VariableEncuesta(props) {
  const {
    encuesta,
    setIsVisibleModal,
    title,
    isVisibleModal,
    ambitoData,
    current,
    setCurrent,
  } = props;
  const token = getAccessTokenApi();
  const [ambitoId, setAmbitoId] = useState();
  const [atributoId, setAtributoId] = useState();
  const [atributoData, setAtributoData] = useState([]);
  const [criterioData, setCriterioData] = useState([]);
  const [criterioId, setCriterioId] = useState();

  const [sostenibilidadData, setSostenibilidadData] = useState([]);
  const [sostenibilidadId, setSostenibilidadId] = useState();

  const [secundarioData, setSecundarioData] = useState([]);
  const [secundarioSelectData, setSecundarioSelectData] = useState({});
  const [secundarioId, setSecundarioId] = useState();

  const [variableEncuestaData, setVariableEncuestaData] = useState({});

  var steps = [
    {
      title: "Ámbito",
      content: (
        <Ambito
          ambitoData={ambitoData}
          current={current}
          setCurrent={setCurrent}
          setAmbitoId={setAmbitoId}
          encuesta={encuesta}
        />
      ),
    },
    {
      title: "Atributo",
      content: (
        <Atributo
          atributoData={atributoData}
          current={current}
          setCurrent={setCurrent}
          setAtributoId={setAtributoId}
          encuesta={encuesta}
        />
      ),
    },
    {
      title: "Criterio",
      content: (
        <Criterio
          criterioData={criterioData}
          current={current}
          setCurrent={setCurrent}
          setCriterioId={setCriterioId}
          encuesta={encuesta}
        ></Criterio>
      ),
    },
    {
      title: "Indicador de sostenibilidad",
      content: (
        <Sostenibilidad
          sostenibilidadData={sostenibilidadData}
          current={current}
          setCurrent={setCurrent}
          setSostenibilidadId={setSostenibilidadId}
          encuesta={encuesta}
        ></Sostenibilidad>
      ),
    },
    {
      title: "Indicador secundario",
      content: (
        <Secundarios
          secundarioData={secundarioData}
          current={current}
          setCurrent={setCurrent}
          setSecundarioData={setSecundarioData}
          setSecundarioSelectData={setSecundarioSelectData}
          setSecundarioId={setSecundarioId}
          encuesta={encuesta}
        ></Secundarios>
      ),
    },
    {
      title: "Variables",
      content: (
        <Variable
          variableData={secundarioSelectData}
          variableEncuestaData={variableEncuestaData}
          current={current}
          setCurrent={setCurrent}
          encuesta={encuesta}
          setVariableEncuestaData={setVariableEncuestaData}
        ></Variable>
      ),
    },
  ];
  useEffect(() => {
    if (ambitoId) {
      getAtributosApi(token, ambitoId).then((response) => {
        setAtributoData(response.atributos);
      });
    }
  }, [token, ambitoId]);

  useEffect(() => {
    if (atributoId) {
      getCriteriosApi(token, atributoId).then((response) => {
        setCriterioData(response.criterios);
      });
    }
  }, [token, atributoId]);

  useEffect(() => {
    if (criterioId) {
      getSostenibilidadesApi(token, criterioId).then((response) => {
        setSostenibilidadData(response.sostenibilidades);
      });
    }
  }, [token, criterioId]);

  useEffect(() => {
    if (sostenibilidadId) {
      getVariablesByIndicadorApi(token, sostenibilidadId).then((response) => {
        setSecundarioData(response.variables);
      });
    }
  }, [token, sostenibilidadId]);

  useEffect(() => {
    if (secundarioId) {
      getVariableEncuestasByEncuestaApi(token, encuesta.id, secundarioId).then(
        (response) => {
          setVariableEncuestaData(response.variableEncuesta);
        }
      );
    }
  }, [token, secundarioId, encuesta]);

  // const next = () => {
  //   setCurrent(current + 1);
  // };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleCancel = () => {
    setIsVisibleModal(false);
  };

  return (
    <>
      <Modal
        title={title}
        centered={true}
        visible={isVisibleModal}
        onCancel={handleCancel}
        footer={false}
        animation={false}
        width={1200}
      >
        <Steps progressDot current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          {/* {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )} */}
          {/* {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              Guardar
            </Button>
          )} */}
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Regresar
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
}
