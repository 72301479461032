import { List, Empty, Card, Progress, Spin } from "antd";
import { countVariablesSostenibilidadApi } from "../../../../api/variable.api";
import { countVariableEncuestasSostenibilidaApi } from "../../../../api/variableEncuesta.api";
import { getAccessTokenApi } from "../../../../api/auth";
import { useState, useEffect } from "react";

export default function Sostenibilidad(props) {
  const {
    sostenibilidadData,
    setSostenibilidadId,
    current,
    setCurrent,
    encuesta,
  } = props;
  const [loadigData, setloadigData] = useState(true);
  const token = getAccessTokenApi();

  const handleSostenibilidadClick = (value) => {
    setSostenibilidadId(value);
    setCurrent(current + 1);
  };

  useEffect(() => {
    if (sostenibilidadData) setloadigData(false);
  }, []);

  function CountVars({ sostenibilidadId, encuestaId }) {
    const [contador, setContador] = useState(null);
    const [contadorEncuesta, setContadorEncuesta] = useState(null);
    useEffect(() => {
      let isMounted = true;
      countVariablesSostenibilidadApi(token, sostenibilidadId).then(
        (response) => {
          if (isMounted) setContador(response.count);
        }
      );
      return () => {
        isMounted = false;
      };
    }, [sostenibilidadId]);

    useEffect(() => {
      let isMounted = true;
      countVariableEncuestasSostenibilidaApi(
        token,
        encuestaId,
        sostenibilidadId
      ).then((response) => {
        if (isMounted) setContadorEncuesta(response.count);
      });
      return () => {
        isMounted = false;
      };
    }, [sostenibilidadId, encuestaId]);

    if (contador === null || contadorEncuesta === null) {
      return <Spin size="small" />;
    }
    if (contadorEncuesta < contador) {
      const percentVal = (contadorEncuesta / contador) * 100;
      return (
        <>
          <Progress percent={percentVal.toFixed(0)} steps={3} />
          <br /> ({contadorEncuesta} de {contador})
        </>
      );
    }
    return (
      <>
        <Progress percent={100} steps={3} />
        <br /> ({contadorEncuesta} de {contador})
      </>
    );
  }

  return (
    <List
      dataSource={sostenibilidadData}
      loading={loadigData}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No existen indicadores de sostenibilidad"
          />
        ),
      }}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 6,
        xxl: 3,
      }}
      renderItem={(sostenibilidad) => (
        <List.Item>
          <Card
            hoverable
            title={sostenibilidad.codigo}
            onClick={() => {
              handleSostenibilidadClick(sostenibilidad.id);
            }}
          >
            <CountVars
              sostenibilidadId={sostenibilidad.id}
              encuestaId={encuesta.id}
            />
            <br />
            <br />
            {sostenibilidad.name}
          </Card>
        </List.Item>
      )}
    />
  );
}
