import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, DatePicker, Button } from "antd";
import { Radar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getFincasAmbitoAvgApi } from "../../../api/finca.api";

export default function Ambito() {
  const token = getAccessTokenApi();
  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [fincaId, setFincaId] = useState([]);
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (fincaId.length > 0) {
      if (fechaInicial && fechaFinal) {
        getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, fincaId).then(
          (response) => {
            setData(response.fincas);
          }
        );
      } else {
        getFincasAmbitoAvgApi(token, 0, 0, fincaId).then((response) => {
          setData(response.fincas);
        });
      }
    } else {
      if (fechaInicial && fechaFinal) {
        getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, 0).then(
          (response) => {
            setData(response.fincas);
          }
        );
      } else {
        getFincasAmbitoAvgApi(token, 0, 0, 0).then((response) => {
          setData(response.fincas);
        });
      }
    }
  }, [token, fincaId, fechaFinal, fechaInicial]);

  const config = {
    data: data,
    xField: "ambito",
    yField: "avgNormalizado",
    seriesField: "name",
    meta: {
      avgNormalizado: {
        alias: "Criticidad",
        min: 0,
        max: 5,
        nice: true,
      },
      ambito: {
        alias: "Ámbito",
      },
      name: {
        alias: "Finca",
      },
    },
    xAxis: {
      line: null,
      tickLine: null,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    annotations: [
      {
        type: "text",
        position: [0, 5],
        content: "Óptimo",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
      {
        type: "text",
        position: [0, 4],
        content: "Adecuado",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
      {
        type: "text",
        position: [0, 3],
        content: "Medio",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
      {
        type: "text",
        position: [0, 2],
        content: "Bajo",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
      {
        type: "text",
        position: [0, 1],
        content: "Nulo",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
    ],
    point: {},
    area: {},
  };

  function onChange(date, dateString) {
    if (date) {
      setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
      setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
    } else {
      setFechaInicial(null);
      setFechaFinal(null);
    }
  }

  const ref = useRef();

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Reportes</Breadcrumb.Item>
        <Breadcrumb.Item>Ámbito</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca>
          </Col>
        </Row>

        <br />
        <h2>Criticidad por ámbito</h2>
        <br />
        <Button
          disabled={disabled}
          htmlType="button"
          className="btn-submit"
          onClick={downloadImage}
        >
          Guardar gráfico
        </Button>
        <br />
        <br />
        <Radar
          {...config}
          onReady={(plot) => {
            ref.current = plot;
            setDisabled(false);
          }}
        />
      </div>
    </div>
  );
}
