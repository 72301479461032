import { Breadcrumb } from "antd";
import { Table, Button } from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./Secundario.scss";

export default function Secundario() {
  const columns = [
    {
      title: "Código indicador",
      width: 120,
      dataIndex: "key",
      key: "key",
      fixed: "left",
    },
    {
      title: "Nombre",
      width: 120,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Nombre ámbito",
      width: 120,
      dataIndex: "nameambito",
      key: "nameambito",
    },
    {
      title: "Nombre atributo",
      width: 130,
      dataIndex: "nameatributo",
      key: "nameatributo",
    },
    {
      title: "Nombre criterio",
      width: 130,
      dataIndex: "namecriterio",
      key: "namecriterio",
    },
    {
      title: "Inidicador sostenibilidad",
      width: 130,
      dataIndex: "namesostenibilidad",
      key: "namesostenibilidad",
    },
    { title: "Resultado Normalizado", dataIndex: "resultnormalized", key: "1" },
    { title: "Formula", dataIndex: "formule", key: "2" },
    { title: "Variable 1", dataIndex: "var1", key: "3" },
    { title: "Variable 2", dataIndex: "var2", key: "4" },
    { title: "Resultado", dataIndex: "result", key: "5" },
    { title: "Criticidad 1", dataIndex: "criticity1", key: "6" },
    { title: "Criticidad 2", dataIndex: "criticity2", key: "7" },
    { title: "Criticidad 3", dataIndex: "criticity3", key: "8" },
    { title: "Criticidad 4", dataIndex: "criticity4", key: "9" },
    { title: "Criticidad 5", dataIndex: "criticity5", key: "10" },
    { title: "Sin dato", dataIndex: "nodata", key: "11" },
    {
      title: "Resultado indicador de sostenibilidad",
      dataIndex: "resultsostnibilidad",
      key: "12",
    },
    { title: "Salida grafica 1", dataIndex: "graphic1", key: "13" },
    { title: "Comentario 1", dataIndex: "comment1", key: "14" },
    { title: "Salida grafica 2", dataIndex: "graphic2", key: "15" },
    { title: "Comentario 2", dataIndex: "comment2", key: "16" },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: () => (
        <div>
          <Button
            size="small"
            type="primary"
            onClick={() => console.log("Editar")}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => console.log("Borrar")}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "E-01.1.1.1",
      name: "Producción de leche",
      nameambito: "Económico - Productivo",
      nameatributo: "Productividad",
      namecriterio: "Productividad",
      namesostenibilidad: "Eficiencia productiva",
      resultnormalized:
        "Tomará valores del 1 al 5 según resultados de cada matriz de los indicadores secundarios",
      formule:
        "∑Resultado_de criticidad_normalizado_indicador_secundario / Número_de_indicadores_secundarios evauados",
      var1: "∑Resultado_de criticidad_normalizado_indicador_secundario",
      var2: "Número_de_indicadores_secundarios evauados",
      result:
        "Promedio de la criticidad de los indicadores secundarios evaluados en el mes de evaluación",
      criticity1: "Desempeño Nulo / Criticidad alta = 1",
      criticity2: "Desempeño Bajo / Criticidad significativa = 2",
      criticity3: "Desempeño Medio /  Criticidad media = 3",
      criticity4: "Desempeño Adecuado / Criticidad baja = 4",
      criticity5: "Desempeño Óptimo / Ausencia de Criticidad = 5",
      nodata: "No evaluado = 0",
      resultsostnibilidad:
        "Tomará valores del 1 al 5 y valoración nominal según la comparación del resultados de aplicar la fórmula_de_cálculo_indicador_de sostenibildiad con las escala de criticidad",
      graphic1:
        "Gráfico tipo barras  con Resultado_de criticidad_normalizado_indicador_secundario ( E-01.1.1.1 Producción de leche), Resultado__normalizado_criticidad_ indicador_sostenibilidad ( E-01.1.1 Eficiencia productiva) y la línea de meta criticidad 5 desempeño óptimo.",
      comment1:
        "Texto libre con un número limitado de caracteres para que el evaluador pueda ingresar observaciones o explicaciones respecto a los resultados obtenidos",
      graphic2:
        "Gráfico tipo amoeba con todos los resultados según criticidad de los indicadores de sotenibilidad (E-01.1.1 Eficiencia productiva E-01.1.2 Rendimiento, E-01.1.3 Retorno, E-01.1.4 Función de control administrativo, E-02.1.1 Autosuficiencia, E-02.2.1 Capacidad de cambio e innovación, A-01.1.1 Calidad del suelo, A-01.02. Conservación, A-02.1.1 Uso de insumos sintéticos, A-02.2.1 Buenas prácticas pecuarias A-02.3.1 Buenas prácticas agrícolas, S-01.1.1 Empoderamiento del grupo familiar, S-01.2.1 Saberes y prácticas ancestrales, G-01.1.1 Gestión de Plan de Acción, G-01.1.2 Gestión del presupuesto)",
      comment2:
        "Texto libre con un número limitado de caracteres para que el evaluador pueda ingresar observaciones o explicaciones respecto a los resultados obtenidos",
    },
  ];

  return (
    <div className="fincas">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Inidicadores</Breadcrumb.Item>
        <Breadcrumb.Item>Sostenibilidad</Breadcrumb.Item>
      </Breadcrumb>
      <Table columns={columns} dataSource={data} scroll={{ x: 10000 }} />
    </div>
  );
}
