import React, { useState, useEffect, useRef } from "react";
import { Radar } from "@ant-design/charts";
import { getFincasVariablesSecundarioAvgApi } from "../../../api/finca.api";
import { getAccessTokenApi } from "../../../api/auth";
import { Button } from "antd";

const Amoeba = (props) => {
  const { sostenibilidadId, fincaId, fechaInicial, fechaFinal } = props;
  const token = getAccessTokenApi();
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (sostenibilidadId > 0) {
      if (fincaId.length > 0) {
        if (fechaInicial && fechaFinal) {
          getFincasVariablesSecundarioAvgApi(
            token,
            sostenibilidadId,
            fechaInicial,
            fechaFinal,
            fincaId
          ).then((response) => {
            setData(response.fincas);
          });
        } else {
          getFincasVariablesSecundarioAvgApi(
            token,
            sostenibilidadId,
            0,
            0,
            fincaId
          ).then((response) => {
            setData(response.fincas);
          });
        }
      } else {
        if (fechaInicial && fechaFinal) {
          getFincasVariablesSecundarioAvgApi(
            token,
            sostenibilidadId,
            fechaInicial,
            fechaFinal,
            0
          ).then((response) => {
            setData(response.fincas);
          });
        } else {
          getFincasVariablesSecundarioAvgApi(
            token,
            sostenibilidadId,
            0,
            0,
            0
          ).then((response) => {
            setData(response.fincas);
          });
        }
      }
    } else {
      setData([]);
    }
  }, [token, sostenibilidadId, fincaId, fechaInicial, fechaFinal]);

  const config = {
    data: data,
    xField: "variable",
    yField: "avgNormalizado",
    seriesField: "name",
    meta: {
      avgNormalizado: {
        alias: "Criticidad",
        min: 0,
        max: 5,
        nice: true,
      },
      variable: {
        alias: "Indicador secundario",
      },
      name: {
        alias: "Finca",
      },
    },
    xAxis: {
      line: null,
      tickLine: null,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    annotations: [
      {
        type: "text",
        position: [0, 5],
        content: "Óptimo",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
      {
        type: "text",
        position: [0, 4],
        content: "Adecuado",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
      {
        type: "text",
        position: [0, 3],
        content: "Medio",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
      {
        type: "text",
        position: [0, 2],
        content: "Bajo",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
      {
        type: "text",
        position: [0, 1],
        content: "Nulo",
        offsetY: 0,
        offsetX: 20,
        style: { textBaseline: "bottom" },
      },
    ],
    point: {},
    area: {},
  };

  // var config = {
  //   data: data,
  //   xField: "name",
  //   yField: "avgNormalizado",
  //   seriesField: "name",
  //   label: {
  //     position: "middle",
  //     style: {
  //       fill: "#FFFFFF",
  //       opacity: 0.6,
  //     },
  //   },
  //   xAxis: {
  //     label: {
  //       autoHide: true,
  //       autoRotate: false,
  //     },
  //   },
  //   meta: {
  //     name: { alias: "Finca" },
  //     avgNormalizado: { alias: "Criticidad" },
  //   },
  //   annotations: [
  //       {
  //       type: "text",
  //       position: ["min", 5],
  //       content: "Óptimo",
  //       offsetY: 15,
  //       offsetX: -105,
  //       style: { textBaseline: "bottom" },
  //     },
  //     {
  //       type: "line",
  //       start: ["min", 5],
  //       end: ["max", 5],
  //       style: {
  //         stroke: "#8eaadb",
  //         lineDash: [2, 2],
  //       },
  //     },
  //     {
  //       type: "text",
  //       position: ["min", 4],
  //       content: "Adecuado",
  //       offsetY: 15,
  //       offsetX: -105,
  //       style: { textBaseline: "bottom" },
  //     },
  //     {
  //       type: "line",
  //       start: ["min", 4],
  //       end: ["max", 4],
  //       style: {
  //         stroke: "#92d050",
  //         lineDash: [2, 2],
  //       },
  //     },
  //     {
  //       type: "text",
  //       position: ["min", 3],
  //       content: "Medio",
  //       offsetY: 15,
  //       offsetX: -105,
  //       style: { textBaseline: "bottom" },
  //     },
  //     {
  //       type: "line",
  //       start: ["min", 3],
  //       end: ["max", 3],
  //       style: {
  //         stroke: "#fdf734",
  //         lineDash: [2, 2],
  //       },
  //     },
  //     {
  //       type: "text",
  //       position: ["min", 2],
  //       content: "Bajo",
  //       offsetY: 15,
  //       offsetX: -105,
  //       style: { textBaseline: "bottom" },
  //     },
  //     {
  //       type: "line",
  //       start: ["min", 2],
  //       end: ["max", 2],
  //       style: {
  //         stroke: "#f8bf32",
  //         lineDash: [2, 2],
  //       },
  //     },
  //     {
  //       type: "text",
  //       position: ["min", 1],
  //       content: "Nulo",
  //       offsetY: 15,
  //       offsetX: -105,
  //       style: { textBaseline: "bottom" },
  //     },
  //     {
  //       type: "line",
  //       start: ["min", 1],
  //       end: ["max", 1],
  //       style: {
  //         stroke: "#ff0000",
  //         lineDash: [2, 2],
  //       },
  //     },
  //   ],
  // };

  const ref = useRef();

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  return (
    <div>
      {/* <button type="button" onClick={downloadImage} style={{ marginRight: 24 }}>
        Guardar gráfico
      </button> */}
      <Button
        disabled={disabled}
        htmlType="button"
        className="btn-submit"
        onClick={downloadImage}
      >
        Guardar gráfico
      </Button>
      <br />
      <br />
      <Radar
        {...config}
        onReady={(plot) => {
          ref.current = plot;
          setDisabled(false);
        }}
      />
    </div>
  );
  //return <Bar {...config} />;
};

export default Amoeba;
