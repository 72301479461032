import React, { useState, useEffect } from "react";
import { Select } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getAllFincasApi } from "../../../../api/finca.api";

import "./SelectMultiFinca.scss";

const { Option } = Select;

export default function SelectMultiFinca(props) {
  const { setFincaId } = props;
  const [fincaData, setFincaData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getAllFincasApi(token).then((response) => {
      setFincaData(response.fincas);
    });
    setLoadingData(false);
  }, [token, loadingData]);

  const handleAtributoChange = (value) => {
    setFincaId(value);
    //console.log(value);
  };

  return (
    <div className="select-finca-form">
      <Select
        placeholder="Seleccione una o varias fincas"
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        //defaultValue={["a10", "c12"]}
        onChange={handleAtributoChange}
      >
        {fincaData && fincaData.map
          ? fincaData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))
          : ""}
      </Select>
    </div>
  );
}
