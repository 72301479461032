import React, { useState, useEffect } from "react";
import { Breadcrumb, Row, Col } from "antd";
import {
  Tooltip,
  MapContainer,
  TileLayer,
  GeoJSON,
  useMap,
} from "react-leaflet";
import { getAllFincasIdApi } from "../../../api/finca.api";

import { getAccessTokenApi } from "../../../api/auth";

import "./Geoposicion.scss";
import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

export default function Zonas() {
  const token = getAccessTokenApi();
  const [fincas, setFincas] = useState([]);
  const position = [-4.065372, -78.953444];
  const [fincaId, setFincaId] = useState([]);
  //const map = useMap();

  useEffect(() => {
    if (fincaId.length > 0) {
      getAllFincasIdApi(token, fincaId).then((response) => {
        setFincas(response.fincas);
        console.log(response.fincas);
      });
    } else {
      getAllFincasIdApi(token, 0).then((response) => {
        setFincas(response.fincas);
        console.log(response.fincas);
      });
    }
  }, [token, fincaId]);

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Mapa</Breadcrumb.Item>
        <Breadcrumb.Item>Zonas geográficas</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Row gutter={16}>
          <Col span={24}>
            <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <MapContainer
              style={{ width: "100%", height: "70vh" }}
              center={position}
              zoom={8}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {fincas
                ? fincas.map(
                    (item) =>
                      item && (
                        <GeoJSON key={item.id} data={JSON.parse(item.zona)}>
                          <Tooltip sticky>
                            <b>{item.name}</b> <br /> {item.productor}
                          </Tooltip>
                        </GeoJSON>
                      )
                  )
                : ""}
            </MapContainer>
          </Col>
        </Row>
      </div>
    </div>
  );
}
