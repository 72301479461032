import moment from "moment";

export function formatDate(dateFull) {
  let dateFormat = moment(dateFull);
  return dateFormat.format("YYYY/MM/DD");
}

export function formatYear(dateFull) {
  let dateFormat = moment(dateFull);
  return dateFormat.format("YYYY");
}

export function formatMonth(dateFull) {
  let dateFormat = moment(dateFull);
  return dateFormat.format("MMM");
}

export function formatMonthYear(dateFull) {
  let dateFormat = moment(dateFull).add(1, "day");
  return dateFormat.format("YYYY/MMM");
}

export function returnCoordinates(polyString) {
  let geojsonFeature = JSON.parse(polyString);
  return geojsonFeature;
}
