import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Empty, Breadcrumb } from "antd";
import { getAccessTokenApi } from "../../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";

import "./Sostenibilidad.scss";

import ModalUser from "../../../../components/Admin/ModalUser";
import EditSostenibilidadForm from "../../../../components/Admin/Sostenibilidad/EditSostenibilidadForm";
import { getAllSostenibilidadesApi } from "../../../../api/sostenibilidad.api";

import "./Sostenibilidad.scss";
import ModalSostenibilidad from "../../../../components/Admin/Sostenibilidad/ModalSostenibilidad";

export default function Sostenibilidad() {
  const [sostenibilidades, setSostenibilidades] = useState([]);
  const token = getAccessTokenApi();
  const [reloadSostenibilidades, setReloadSostenibilidades] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [isVisibleModalFicha, setIsVisibleModalFicha] = useState(false);
  const [modalTitleFicha, setModalTitleFicha] = useState();

  const [sostenibilidadFicha, setSostenibilidadFicha] = useState({});

  useEffect(() => {
    getAllSostenibilidadesApi(token, true).then((response) => {
      setSostenibilidades(response.sostenibilidades);
      setLoadingData(false);
    });

    setReloadSostenibilidades(false);
  }, [token, reloadSostenibilidades]);

  const columns = [
    {
      title: "Código",
      width: 40,
      dataIndex: "codigo",
      key: "codigo",
      fixed: "left",
    },
    {
      title: "Nombre",
      width: 80,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Ámbito",
      width: 100,
      dataIndex: ["criterio", "atributo", "ambito", "name"],
      key: "criterio.atributo.ambitoId",
    },
    {
      title: "Atributo",
      width: 100,
      dataIndex: ["criterio", "atributo", "name"],
      key: "criterio.atributoId",
    },
    {
      title: "Criterio de sostenibilidad",
      width: 100,
      dataIndex: ["criterio", "name"],
      key: "criterioId",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editSostenibilidad(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => fichaSostenibilidad(record)}
          >
            <EyeOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteSostenibilidad(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const addSostenibilidad = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo indicador de sostenibilidad");
    setmodalContent(
      <EditSostenibilidadForm
        sostenibilidad={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadSostenibilidades={setReloadSostenibilidades}
        buttonName="Agregar indicador de sostenibilidad"
        addSlag={"add"}
      />
    );
  };
  const editSostenibilidad = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar indicador de sostenibilidad");
    setmodalContent(
      <EditSostenibilidadForm
        sostenibilidad={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadSostenibilidades={setReloadSostenibilidades}
        buttonName="Editar indicador de sostenibilidad"
        addSlag={"edit"}
      />
    );
  };

  const deleteSostenibilidad = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar indicador de sostenibilidad");
    setmodalContent(
      <EditSostenibilidadForm
        sostenibilidad={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadSostenibilidades={setReloadSostenibilidades}
        buttonName="Eliminar indicador de sostenibilidad"
        addSlag={"delete"}
      />
    );
  };
  const fichaSostenibilidad = (record) => {
    setIsVisibleModalFicha(true);
    setSostenibilidadFicha(record);
    setModalTitleFicha("Ficha indicador de sostenibilidad");
  };
  return (
    <div className="fincas">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Inidicadores</Breadcrumb.Item>
        <Breadcrumb.Item>Indicadores de sostenibilidad</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addSostenibilidad}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={sostenibilidades}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen indicadores de sostenibilidades"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
      >
        {modalContent}
      </ModalUser>

      <ModalSostenibilidad
        title={modalTitleFicha}
        isVisibleModal={isVisibleModalFicha}
        setIsVisibleModal={setIsVisibleModalFicha}
        sostenibilidadFicha={sostenibilidadFicha}
      />
    </div>
  );
}
